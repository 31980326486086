<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	CASHBACK_ANNOUNCEMENT_DESCRIPTION,
	CASHBACK_ANNOUNCEMENT_END_DATE,
	CASHBACK_ANNOUNCEMENT_HEADER,
	CASHBACK_ANNOUNCEMENT_TITLE,
	SOUNDS_GREAT,
} from '@/locales/constants';
import money from '@/assets/icons/money-update.svg';
import { onMounted, ref } from 'vue';
import { formatExpirationDate } from '@/utils/helpers';

const { options } = defineProps<{
	options: {
		expiry: string;
		percentage: number;
	};
}>();

const emit = defineEmits<{
	'close-modal': [];
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const expireDate = ref('');

onMounted(async () => {
	if (!options.expiry) return '';
	expireDate.value = await formatExpirationDate(options.expiry);
});
</script>

<template>
	<div class="cashback-announcement">
		<ModalCommonContent>
			<template #header>
				{{
					$t(CASHBACK_ANNOUNCEMENT_HEADER, { percentage: options.percentage })
				}}
			</template>
			<template #icon>
				<img :src="money" alt="money icon" width="9.375rem" height="9.375rem" />
			</template>
			<template #title>
				{{
					$t(CASHBACK_ANNOUNCEMENT_TITLE, { percentage: options.percentage })
				}}
			</template>
			<template #description>
				{{
					$t(CASHBACK_ANNOUNCEMENT_DESCRIPTION, {
						percentage: options.percentage,
					})
				}}
			</template>
			<template #default>
				<PrimeText tag="p" size="sm" color="primary" weight="600">
					{{ $t(CASHBACK_ANNOUNCEMENT_END_DATE, { expiry: expireDate }) }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					type="fill"
					rounded="rounded"
					full-width
					:label="$t(SOUNDS_GREAT)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.cashback-announcement {
	:deep(.modal-common-content) {
		.content-icon {
			width: 9.375rem;
			height: 9.375rem;
		}

		.content-description {
			border-bottom: none;
			margin-bottom: 0;
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
