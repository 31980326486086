<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';
import { PiArrowRepeatDuotone } from '@primeinsightsgroupllc/prime-icons';
import {
	CASHBACK_ANNOUNCEMENT_HEADER,
	REWARDS_CASHBACK_SUBTITLE,
} from '@/locales/constants';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { onMounted, ref } from 'vue';
import { formatExpirationDate } from '@/utils/helpers';

const { cashbackDetails } = storeToRefs(useRewardsStore());

const expireDate = ref('');

onMounted(async () => {
	if (!cashbackDetails.value?.expiry) return '';
	expireDate.value = await formatExpirationDate(cashbackDetails.value?.expiry);
});
</script>

<template>
	<div class="cashback-details__container">
		<div class="cashback-details__wrapper">
			<PiArrowRepeatDuotone
				color="base-primary"
				class="cashback-details__back-icon"
			/>
			<div class="cashback-details__content">
				<PrimeText
					tag="p"
					class="cashback-details__title"
					size="sm"
					weight="700"
					color="primary"
				>
					{{
						$t(CASHBACK_ANNOUNCEMENT_HEADER, {
							percentage: cashbackDetails?.percentage,
						})
					}}
				</PrimeText>
				<PrimeText
					tag="p"
					class="cashback-details__subtitle"
					size="xs"
					color="primary"
					weight="500"
				>
					{{
						$t(REWARDS_CASHBACK_SUBTITLE, {
							percentage: cashbackDetails?.percentage,
							expiry: expireDate,
						})
					}}
				</PrimeText>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.cashback-details__container {
	margin-bottom: 0.5rem;
	padding: 1.125rem 1rem;
	border: 2px solid var(--p-primary-base);
	border-radius: var(--p-radius-2xl);
	background-color: var(--base-primary-09);
}

.cashback-details__wrapper {
	display: flex;
	gap: 0.875rem;
	justify-content: center;
	align-items: center;
}

.cashback-details__back-icon {
	flex-shrink: 0;
}
</style>
