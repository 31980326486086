<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import AchievementIcon from '@/views/achievements/components/AchievementIcon.vue';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	ACHIEVEMENT_UNLOCKED,
	GO_TO_ACHIEVEMENTS_AND_REDEEM_BONUS,
	GO_TO_ACHIEVEMENTS,
} from '@/locales/constants';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import type { AchievementUnlockedLevel } from '@/types';

const router = useRouter();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const props = defineProps<{
	options?: AchievementUnlockedLevel;
}>();

const handleCloseModal = () => {
	emit('close-modal');

	router.push({ name: 'achievements' });
};

const achievement_key = ref(props?.options?.achievement_key);
const level_key = ref(props?.options?.level_key);
const name = ref(props?.options?.name);
const description = ref(props?.options?.description);
</script>

<template>
	<div class="achievements_unlocked-modal">
		<ModalCommonContent>
			<template #header>{{ $t(ACHIEVEMENT_UNLOCKED) }}</template>
			<template #icon>
				<AchievementIcon
					:achievement-key="achievement_key"
					:level-key="level_key"
					:alt="description"
					active
				/>
			</template>
			<template #title>{{ name }}</template>
			<template #description>{{ description }}</template>
			<template #message>
				{{ $t(GO_TO_ACHIEVEMENTS_AND_REDEEM_BONUS) }}
			</template>
			<template #actions>
				<PrimeButton
					full-width
					type="gradient"
					:label="$t(GO_TO_ACHIEVEMENTS)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.achievements_unlocked-modal {
	:deep(.modal-common-content) {
		.content-icon {
			margin-bottom: 0.75rem;
			width: 6.875rem;
			height: 6.875rem;
		}

		.content-title {
			font-weight: 700;
		}

		.content-description {
			font-size: 0.75rem;

			@include breakpoint(mobile) {
				font-size: 0.875rem;
			}
		}

		.content-message {
			max-width: 100%;
			color: var(--grey-700);
			font-size: 0.75rem;

			@include breakpoint(mobile) {
				font-size: 0.875rem;
			}
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
