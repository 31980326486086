<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	CASHBACK_NOTIFICATION_DESCRIPTION,
	CASHBACK_NOTIFICATION_HEADER,
	CASHBACK_NOTIFICATION_TITLE,
	SOUNDS_GREAT,
} from '@/locales/constants';
import hand from '@/assets/icons/hand-coin.svg';

const { options } = defineProps<{
	options: {
		percentage: number;
	};
}>();

const emit = defineEmits<{
	'close-modal': [];
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<div class="cashback-received-notification">
		<ModalCommonContent>
			<template #header>
				{{
					$t(CASHBACK_NOTIFICATION_HEADER, { percentage: options.percentage })
				}}
			</template>
			<template #icon>
				<img :src="hand" alt="hand icon" width="9.375rem" height="9.375rem" />
			</template>
			<template #title>
				{{
					$t(CASHBACK_NOTIFICATION_TITLE, { percentage: options.percentage })
				}}
			</template>
			<template #description>
				{{
					$t(CASHBACK_NOTIFICATION_DESCRIPTION, {
						percentage: options.percentage,
					})
				}}
			</template>
			<template #actions>
				<PrimeButton
					type="fill"
					rounded="rounded"
					full-width
					:label="$t(SOUNDS_GREAT)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.cashback-received-notification {
	:deep(.modal-common-content) {
		.content-icon {
			width: 9.375rem;
			height: 9.375rem;
		}

		.content-description {
			border-bottom: none;
			margin-bottom: 0;
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
