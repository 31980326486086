<script lang="ts" setup>
import { ref } from 'vue';
import { PrimeSkeleton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { VITE_CDN_DOMAIN } = import.meta.env;

const props = withDefaults(
	defineProps<{
		achievementKey: string | undefined;
		levelKey: string | undefined;
		alt: string | undefined;
		active?: boolean;
	}>(),
	{
		active: false,
	}
);

const isIconLoaded = ref(false);
const fallbackIcon = ref(
	`${VITE_CDN_DOMAIN}/images/achievements/achievement.png`
);
const iconUrl = ref(
	`${VITE_CDN_DOMAIN}/images/achievements/${props.achievementKey}/${props.levelKey}.png`
);

const onIconLoad = () => {
	isIconLoaded.value = true;
};
</script>

<template>
	<div v-if="!isIconLoaded" class="achievement-icon">
		<PrimeSkeleton class="achievement-skeleton" radius="50%" />
	</div>
	<img
		v-show="isIconLoaded"
		class="achievement-icon"
		:class="{ active }"
		:src="iconUrl"
		:alt="alt"
		@load="onIconLoad"
		@error="iconUrl = fallbackIcon"
	/>
</template>

<style lang="scss" scoped>
.achievement-icon {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	width: 100%;
	height: auto;
	object-fit: contain;
	border-radius: 50%;
	filter: grayscale(1);

	&.active {
		filter: none;
	}
}

.achievement-skeleton {
	padding-bottom: 100%;
}
</style>
