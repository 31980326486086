import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { onBeforeMount, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import {
	LEADERBOARD_MODAL,
	NEW_PASSWORD_SENT,
	SET_USER_PASSWORD_MODAL,
} from '@/constants/modals';
import { ColorModes, LeaderboardTypes } from '@/enums';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useMessagesStore } from '@/stores/messages';
import { useAppStore } from '@/stores/app';
import { appColorMode } from '@/utils/local-storage';
import {
	enable as enableDarkMode,
	disable as disableDarkMode,
	auto as followSystemColorScheme,
	setFetchMethod,
} from 'darkreader';
import type { DynamicThemeFix } from 'darkreader';

export const useAppInit = () => {
	const modalStorage = useModalStorage();
	const { activeModal } = storeToRefs(modalStorage);
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const messageStore = useMessagesStore();
	const {
		leaderboard,
		isPasswordSet,
		userData,
		isInitialDataLoading,
		isDarkModeEnabled,
	} = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { authToken } = useAppCookies();
	const appStore = useAppStore();
	const { getTrackingMaid } = storeToRefs(appStore);

	// Set user token if it is in cookies
	onBeforeMount(() => {
		if (!userAuthToken.value && authToken) {
			authStore.setToken(authToken);
		}
	});

	// Close new password sent modal if it is opened
	onBeforeMount(async () => {
		if (activeModal.value.name === NEW_PASSWORD_SENT) {
			await modalStorage.shiftModal();
		}

		await appStore.fetchAppMeta();
	});

	const init = async () => {
		try {
			userStore.setInitialDataLoading(true);
			await Promise.all([
				userStore.fetchUserData(),
				userStore.fetchUserMeta(),
				userStore.fetchFeatures(),
			]);
		} finally {
			userStore.setInitialDataLoading(false);
		}

		await Promise.allSettled([
			userStore.fetchTiersList(),
			messageStore.fetchUserNotifications(),
			messageStore.fetchUserAnnouncements(),
		]);

		if (getTrackingMaid.value) {
			await appStore.storeMaid();
		}
	};

	// Fetch user data based on token
	watch(
		userAuthToken,
		async (token) => {
			if (token) {
				await init();

				//If user does not have password - show modal to set it
				if (userData.value && !isPasswordSet.value) {
					modalStorage.createNewModal(SET_USER_PASSWORD_MODAL, {
						useDefaultClose: false,
					});
				}
			}
		},
		{ immediate: true }
	);

	// Fetch user data based on user id from fetched previously user data
	watch(isInitialDataLoading, async (newValue, oldValue) => {
		const dataFetchCompleted = !newValue && oldValue;
		if (dataFetchCompleted && leaderboard.value.has_enough_completes) {
			modalStorage.createNewModal(LEADERBOARD_MODAL, {
				type: LeaderboardTypes.UNLOCKED,
			});
		}
	});

	onMounted(() => {
		console.log('ENVS:::');
		console.log(import.meta.env);
	});

	const darkReaderTheme = {
		brightness: 100,
		contrast: 100,
		sepia: 0,
	};

	const darkReaderFix = {
		css:
			'.bg-\\[\\#28292B\\] { background-color: #28292B !important; }' +
			'.bg-\\[\\#3D3E40\\] { background-color: #3D3E40 !important; }' +
			'.bg-\\[\\#FAFAFA\\] { background-color: #FAFAFA !important; }' +
			'.pi-widget-chat-border-bottom--dark, .pi-widget-chat-border--dark, .pi-widget-chat-border-top--dark { border-color: #363840 !important; }',
	};

	watch(
		appColorMode,
		(value) => {
			setFetchMethod(window.fetch);
			switch (value) {
				case ColorModes.LIGHT:
					disableDarkMode();
					break;
				case ColorModes.DARK:
					enableDarkMode(darkReaderTheme, darkReaderFix as DynamicThemeFix);
					break;
				case ColorModes.SYSTEM:
					followSystemColorScheme(
						darkReaderTheme,
						darkReaderFix as DynamicThemeFix
					);
					break;
			}
		},
		{ immediate: true }
	);

	watch(
		isDarkModeEnabled,
		(newValue) => {
			// we need such check because initial value of isDarkModeEnabled is undefined
			if (newValue === false && appColorMode.value !== ColorModes.LIGHT) {
				appColorMode.value = ColorModes.LIGHT;
			}
		},
		{ immediate: true }
	);

	return {
		init,
	};
};
