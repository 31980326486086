<script setup lang="ts">
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	ALLOW_LOCATION_TITLE,
	ALLOW_LOCATION_DESCRIPTION,
	OKAY,
} from '@/locales/constants';
import location from '@/assets/icons/location.svg';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useInMarket } from '@/utils/composables/useInMarket';

const emit = defineEmits<{
	'close-modal': [];
}>();

const { launchInMarket } = useInMarket();

const askPermission = async () => {
	launchInMarket().then(() => {
		emit('close-modal');
	});
};
</script>
<template>
	<ModalCommonContent class="allow-location-modal">
		<template #header>
			{{ $t(ALLOW_LOCATION_TITLE) }}
		</template>
		<template #icon>
			<img
				:src="location"
				:alt="$t(ALLOW_LOCATION_TITLE)"
				class="location-img"
			/>
		</template>
		<template #default>
			<PrimeText tag="p" weight="600" size="lg">
				{{ $t(ALLOW_LOCATION_DESCRIPTION) }}
			</PrimeText>
		</template>
		<template #actions>
			<PrimeButton
				rounded="rounded"
				full-width
				:label="$t(OKAY)"
				@click="askPermission"
			/>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
.allow-location-modal :deep(.content-icon) {
	width: 12.5rem;
	height: 12.5rem;
}
</style>
