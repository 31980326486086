import {
	CHARITY,
	GIFT_CARDS,
	PREPAID_CARDS,
	TRANSFER,
} from '@/locales/constants';
import { RewardCategories } from '@/enums';
import type { RewardCategory } from '@/types';
import {
	PiBank,
	PiCreditCard,
	PiGift,
	PiHeartbeat,
} from '@primeinsightsgroupllc/prime-icons';

export const categories: Record<RewardCategories, RewardCategory> = {
	[RewardCategories.TRANSFERS]: {
		icon: PiBank,
		name: TRANSFER,
	},
	[RewardCategories.GIFT_CARDS]: {
		icon: PiGift,
		name: GIFT_CARDS,
	},
	[RewardCategories.PREPAID_CARDS]: {
		icon: PiCreditCard,
		name: PREPAID_CARDS,
	},
	[RewardCategories.CHARITY]: {
		icon: PiHeartbeat,
		name: CHARITY,
	},
	[RewardCategories.PROMOTED]: {
		icon: PiBank,
		name: TRANSFER,
	}
};
