import { RewardCategories, RewardsSubCategories } from '@/enums';
import {
	PiForkKnifeDuotone,
	PiGameControllerDuotone,
	PiHeartDuotone,
	PiShoppingDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import type { Component } from 'vue';
import type { RewardMetaSubCategory, Rewards } from '@/types';
import { ALL, FOOD, GAMING, POPULAR, SHOPPING } from '@/locales/constants';

export type SubCategoryContent = {
	label: string;
	icon: Component | null;
	static: boolean;
};

export const subCategoriesConfig: Record<
	RewardsSubCategories,
	SubCategoryContent
> = {
	[RewardsSubCategories.ALL]: {
		label: ALL,
		icon: null,
		static: true,
	},
	[RewardsSubCategories.POPULAR]: {
		label: POPULAR,
		icon: PiHeartDuotone,
		static: false,
	},
	[RewardsSubCategories.FOOD]: {
		label: FOOD,
		icon: PiForkKnifeDuotone,
		static: false,
	},
	[RewardsSubCategories.GAMING]: {
		label: GAMING,
		icon: PiGameControllerDuotone,
		static: false,
	},
	[RewardsSubCategories.SHOPPING]: {
		label: SHOPPING,
		icon: PiShoppingDuotone,
		static: false,
	},
};

export const defaultSubCategories: Record<string, string> = {
	[RewardCategories.TRANSFERS]: RewardsSubCategories.ALL,
	[RewardCategories.GIFT_CARDS]: RewardsSubCategories.ALL,
	[RewardCategories.PREPAID_CARDS]: RewardsSubCategories.ALL,
	[RewardCategories.CHARITY]: RewardsSubCategories.ALL,
	[RewardCategories.PROMOTED]: RewardsSubCategories.ALL,
};

// Function returns the rewards filtered by the selected subcategories for each category
// Result is used if no text search is applied in rewards modal
export const filterRewardsBySubCategory = (
	rewards: Rewards,
	selectedSubCategories: Record<string, string>,
	categoriesWithSubCategories: Record<RewardCategories, boolean>
) => {
	const filteredRewards = {} as Rewards;

	Object.keys(rewards).forEach((category) => {
		if (!categoriesWithSubCategories[category as RewardCategories]) {
			filteredRewards[category as RewardCategories] =
				rewards[category as RewardCategories];
			return;
		}
		const categoryName = category as RewardCategories;
		const subCategory = selectedSubCategories[categoryName];

		switch (subCategory) {
			case RewardsSubCategories.ALL:
				filteredRewards[categoryName] = rewards[categoryName];
				break;
			case RewardsSubCategories.POPULAR:
				filteredRewards[categoryName] = rewards[categoryName].filter(
					(item) => item.is_popular
				);
				break;
			default:
				filteredRewards[categoryName] = rewards[categoryName].filter(
					(item) => item.sub_category_id === +subCategory
				);
		}
	});

	return filteredRewards;
};

// This function is used to get the subcategories content for each category
// { [categoryName]: { [subCategoryName]: SubCategoryContent } }
export const getRewardsSubCategoriesContent = (
	rewards: Rewards,
	subCategories: RewardMetaSubCategory[],
	categoriesWithSubCategories: Record<RewardCategories, boolean>
) => {
	const result = {} as Record<
		RewardCategories,
		Record<string, SubCategoryContent>
	>;

	Object.values(RewardCategories).forEach((categoryName) => {
		if (!categoriesWithSubCategories[categoryName]) return;
		result[categoryName] = {} as Record<string, SubCategoryContent>;

		// Fill with static subcategories such as All and Popular for example
		Object.entries(subCategoriesConfig).forEach(([key, value]) => {
			if (value.static) {
				result[categoryName][key] = value;
			}
		});

		// Set Popular category only if at least one item has it
		if (rewards[categoryName].some((reward) => reward.is_popular)) {
			result[categoryName][RewardsSubCategories.POPULAR] =
				subCategoriesConfig[RewardsSubCategories.POPULAR];
		}

		// Check if the subcategory is in the rewards list exists at least once
		subCategories.forEach((subCategory) => {
			if (
				rewards[categoryName] &&
				rewards[categoryName].some(
					(item) => item.sub_category_id === subCategory.id
				)
			) {
				result[categoryName][subCategory.id.toString()] =
					subCategoriesConfig[
						subCategory.id.toString() as RewardsSubCategories
					];
			}
		});
	});

	return result;
};
