import { useMobileApp } from '@/utils/composables/useMobileApp';
import { CapacitorInMarket } from '@primeinsightsgroupllc-inmarket/capacitor-inmarket';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

export type PermissionStatus = 'prompt' | 'denied' | 'granted';

export const useInMarket = () => {
	const { userId } = storeToRefs(useUserStore());
	const { isMobileApp } = useMobileApp();

	const permissionStatus = ref<PermissionStatus | null>(null);

	const checkLocationPermissions = async (): Promise<PermissionStatus> => {
		const { location: status } = await CapacitorInMarket.checkPermissions();
		permissionStatus.value = status;
		return status;
	};

	const requestPermissions = async (): Promise<PermissionStatus> => {
		await CapacitorInMarket.requestPermissions();
		return await checkLocationPermissions();
	};

	const launchInMarket = async () => {
		if (!userId.value) return;
		await CapacitorInMarket.setUserId({ userId: userId.value });
		await CapacitorInMarket.startMonitoring();
		return;
	};

	const initInMarket = async () => {
		if (!isMobileApp) return;

		const status = await checkLocationPermissions();
		if (status !== 'denied') {
			await launchInMarket();
		}
	};

	return {
		initInMarket,
		requestPermissions,
		launchInMarket,
		checkLocationPermissions,
	};
};
