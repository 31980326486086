<script setup lang="ts">
import { computed } from 'vue';
import type { IconColors } from '@/types';
import { TierKeys } from '@/enums';
import { TIER_BONUS } from '@/locales/constants';
import { Benefits, benefitsContent } from '@/components/tiers/benefits';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = defineProps<{
	tierKey: IconColors;
	benefit: Benefits;
	dynamicValue?: string | number;
}>();

const content = computed(() => benefitsContent[props.benefit]);

const benefitBorderColor = computed(
	() => `var(--benefit-border-${props.tierKey})`
);

const benefitBackgroundColor = computed(
	() => `var(--benefit-background-gradient-${props.tierKey})`
);

const benefitTextColor = computed(() => `var(--${props.tierKey})`);
</script>

<template>
	<div
		class="benefit__container"
		:class="{ 'gradient-border': tierKey === TierKeys.DIAMOND }"
	>
		<component :is="content.icon" :color="tierKey" size="2rem" />
		<div class="benefit-description">
			<PrimeText size="sm" weight="500">
				{{ `${$t(tierKey)} ${$t(TIER_BONUS)}` }}
			</PrimeText>
			<PrimeText size="sm" weight="600" class="description-bottom">
				{{
					$t(content.text, {
						dynamicValue,
					})
				}}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.benefit__container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.625rem;
	border: 1px solid v-bind(benefitBorderColor);
	border-radius: 0.25rem;
	background: v-bind(benefitBackgroundColor);

	&.gradient-border {
		position: relative;
		border-color: transparent;
		&::before {
			content: '';
			position: absolute;
			inset: 0;
			border-radius: 0.25rem;
			padding: 1px;
			background: v-bind(benefitBorderColor);
			-webkit-mask:
				linear-gradient(#fff 0 0) content-box,
				linear-gradient(#fff 0 0);
			-webkit-mask-composite: xor;
			mask-composite: exclude;
		}
	}
}

.benefit-description {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 1rem;
	color: v-bind(benefitTextColor);

	& span {
		color: inherit !important;
	}
}
</style>
