{
	"sign_in": "Sign In",
	"gotcha": "Gotcha",
	"save": "Save",
	"privacy_policy": "Privacy Policy",
	"legal_information": "Legal information",
	"email": "Email",
	"password": "Password",
	"reset_password": "Reset Password",
	"accept_policy": "I accept the",
	"confirm_email": "Confirm your email",
	"confirm_email_message": "Please click the link in the confirmation email.",
	"resend": "Resend",
	"email_required": "Email is required",
	"email_not_valid": "Email is not valid",
	"password_required": "Password is required",
	"password_min_length": "Password should be at least 8 characters long",
	"min_coins_value": "From {value} points",
	"coins": "points",
	"points": "Points",
	"select": "Select",
	"coins_reached": "points reached",
	"success": "Success",
	"screenout": "Screenout",
	"bonus": "Bonus",
	"claim": "claim",
	"balance": "Balance",
	"congrats": "Congrats!",
	"well_done": "Well Done!",
	"check_it_out": "Check It Out",
	"you_can_claim": "You can claim your reward",
	"balance_rest": "You will have {value} points left after claiming this reward",
	"reward_sent": "Your reward is on the way!",
	"reward_receive_time": "You will receive it via email within 30 minutes.",
	"okay": "Okay",
	"send": "Send",
	"enter_new_password": "Enter new password",
	"confirm_password": "Confirm Password",
	"confirm": "Confirm",
	"confirm_password_required": "Password confirmation required",
	"updated_balance": "Your balance has been updated",
	"something_went_wrong": "Something went wrong",
	"try_again": "Please try again",
	"refresh_page": "Refresh page",
	"transactions": "Transactions",
	"transactions_empty_title": "No Transactions yet",
	"transactions_empty_description": "You don’t have any transaction yet",
	"select_reward": "Select reward",
	"select_rewards": "Select rewards",
	"search_rewards": "Search rewards...",
	"change_reward": "Change reward",
	"no_rewards_message": "No rewards at the moment",
	"claim_reward": "Claim Reward",
	"transfer": "Transfer | Transfers",
	"prepaid_cards": "Prepaid Card | Prepaid Cards",
	"gift_cards": "Gift Card | Gift Cards",
	"charity": "Charity Donation | Charity Donations",
	"view_more_rewards": "View {value} more",
	"no_rewards_with_name": "There are no available rewards available for \"{value}\".",
	"surveys": "Surveys",
	"account_settings": "Account Settings",
	"reward_history": "Reward History",
	"rewards": "Rewards",
	"reward": "Reward",
	"delete": "Delete",
	"delete_account": "Delete Account",
	"delete_account_question": "Are you sure you want to delete your account?",
	"delete_account_information": "Please enter password to delete account. This can not be undone",
	"no_rewards_history_title": "No rewards yet",
	"no_rewards_history_message": "You did not withdraw any rewards yet",
	"collect_points": "Collect Points",
	"done": "Done",
	"processing": "Processing",
	"cancelled": "Cancelled",
	"amount": "Amount",
	"coins_used": "Points Used",
	"date": "Date",
	"set_username": "Set Username",
	"change_username": "Change Username",
	"enter_username": "Enter Username",
	"username_min_length": "Username too short",
	"username_max_length": "Username too long",
	"username_allowed_symbols": "Username must contain letters, numbers or underscore",
	"username_required": "Username required",
	"change_timezone": "Change Timezone",
	"change_email": " Change Email",
	"enter_new_email": "Enter your new email",
	"new_email": "New email",
	"your_password": "Your password",
	"change_password": "Change Password",
	"old_password": "Old Password",
	"welcome_bonus": "Welcome Bonus",
	"bonus_warning_message": "You may redeem rewards after reaching {coins} due to the {bonus} you took",
	"unable_claim": "Unable to claim",
	"redeem_from": "Redeem from",
	"choose": "Choose your",
	"current_streak": "Current Streak",
	"streak_rules": "Complete at least 1 survey to keep the streak",
	"streak_weekly_reward": "Reward for weekly streak",
	"prize_draw": "Prize Draw",
	"draw_prize": "Draw Prize",
	"up_to": "Up to",
	"prize_claimed": "Prize claimed",
	"check_tomorrow": "Check back tomorrow",
	"streak_gongrats_title": "You completed your daily streak",
	"streak_gongrats_description": "Complete at least 1 survey per day to keep your streak and win up to {value} Points every week",
	"congratulations": "Congratulations!",
	"you_won": "You won",
	"open_box": "Open box",
	"select_prize_box": "Select a box to pick free prize",
	"lvl": "lvl",
	"level": "Level",
	"level_up": "Level Up",
	"user": "User",
	"earned": "Earned",
	"prize": "Prize",
	"coins_for_next_level": "points for next level",
	"next_lvl_reward": "Next LVL Reward",
	"you_reached": "You reached",
	"we_credited_you": "We credited you",
	"to_reach": "to reach",
	"earn": "Earn",
	"bonus_information": "{coins} Points ≈ {money} {currency}",
	"bonus_information_2": "{coins} Points ( ≈ {money} {currency})",
	"fast_payout": "Fast Payout",
	"most_popular": "Most Popular",
	"biggest_bonus": "Biggest Bonus",
	"sunday": "Sun",
	"monday": "Mon",
	"tuesday": "Tue",
	"wednesday": "Wed",
	"thursday": "Thu",
	"friday": "Fri",
	"saturday": "Sat",
	"january": "January",
	"february": "February",
	"march": "March",
	"april": "April",
	"may": "May",
	"june": "June",
	"july": "July",
	"august": "August",
	"september": "September",
	"october": "October",
	"november": "November",
	"december": "December",
	"as_a_welcome_bonus": "as a Welcome Bonus",
	"more_coins_to_withdraw": "more coins to withdraw",
	"withdraw_coins": "Withdraw coins for cash or gift cards.",
	"logout": "Logout",
	"get_welcome_bonus": "Get Welcome Bonus",
	"referral": "Referral",
	"referrals": "Referrals",
	"ref_page_title": "Refer a friend",
	"ref_page_description": "For every friend that signs up through your link you receive a 100 coins reward upon your friend's first withdrawal!",
	"ref_how_it_works": "How it works",
	"ref_hiw_step-1_title_code": "Invite your friends",
	"ref_hiw_step-2_title_code": "Share your bonus code",
	"ref_hiw_step-3_title_code": "Receive your commission",
	"ref_share_button": "Copy Link",
	"ref_share_attention": "You can only have one account and you can not refer yourself!",
	"ref_stats_earned_coins_2": "Points Earned",
	"ref_stats_registered_friends": "Friends signed up",
	"statistics": "Statistics",
	"profile_points_earned2": "Points earned",
	"profile_points_rating": "You are in the top {value}% of users!",
	"profile_surveys_completed": "Surveys Completed",
	"profile_rewards_redeemed": "Rewards Redeemed",
	"profile_longest_streak": "Longest Streak",
	"profile_longest_streak_days": "{value} Days",
	"profile_leaderboard_record": "Leaderboard Record",
	"profile_leaderboard_place": "{value} Place",
	"coming_soon": "Coming soon",
	"joined": "Joined",
	"current_time": "Current time",
	"you_won_coins": "You won {amount} points",
	"up_to_coins": "Up to {amount} points",
	"you_reached_level": "You reached <b>Level {value}</b>",
	"we_credited_you_coins": "We credited you {amount} points",
	"earn_coins_to_reach_level": "Earn {amount} points to reach Level {value}",
	"we_credited_coins_welcome_bonus": "We credited you <b>{amount} points</b> as a Welcome Bonus",
	"earn_coins_to_withdraw": "Earn {amount} more points to withdraw",
	"selected_reward": "Selected Reward",
	"redeem_reward_welcome_bonus": "You may redeem rewards after reaching <span class='bold'>{amount} Points</span> due to the <span class='bold'>Welcome Bonus</span> you took",
	"activities": "Activities",
	"help": "Help",
	"keep_it_up": "Keep it up!",
	"rating_current": "Current rating",
	"rating_description": "Every month we are awarding our most loyal members with great rewards",
	"rating_description_weekly": "Every week we are awarding our most loyal members with great rewards",
	"leaderboard": "Leaderboard",
	"leaderboard_monthly": "Monthly Leaderboard",
	"leaderboard_check": "Check Leaderboard",
	"leaderboard_locked_title": "Unlock leaderboard!",
	"leaderboard_locked_message": "Complete {value} more survey(s) to start competing",
	"leaderboard_locked_progress": "{value}/{target} surveys completed",
	"leaderboard_unlocked_title": "You have unlocked the leaderboard!",
	"leaderboard_scored_title": "You scored a place on the leaderboard!",
	"leaderboard_won_title": "You scored <b>place</b> <span>{place}</span> on last month's leaderboard.",
	"leaderboard_won_title_weekly": "You scored <b>place</b> <span>{place}</span> on last weekly leaderboard.",
	"leaderboard_unlocked_message": "Please choose a username to compete with other members",
	"leaderboard_scored_message": "Win great prizes every month",
	"leaderboard_scored_message_weekly": "Win great prizes every week",
	"leaderboard_won_message": "We credited you {value} points!",
	"leaderboard_table_title": "Top Earners",
	"leaderboard_table_description": "Income from completed surveys counts",
	"leaderboard_ended": "Leaderboard ended",
	"authorization_failed": "Authorization failed",
	"no_more_entries": "No More Entries",
	"loading_more": "Loading More...",
	"other_transaction": "Other",
	"email_preferences": "E-mail Preferences",
	"email_subs_title": "Deactivate emails you would like to unsubscribe from",
	"email_subs_save": "Update Preferences",
	"email_subs_show_all": "Show all email preferences",
	"email_sub_offers": "Special Offers",
	"email_sub_reminders": "Reminders",
	"email_sub_weekly_report": "Weekly Report",
	"email_sub_account_updates": "Account Updates",
	"email_sub_survey_invitations": "Survey Invitations",
	"email_sub_offers_description": "Special offers for you to earn even more",
	"email_sub_reminders_description": "Reminders when you did not take surveys in  a while or missing out on a great offer",
	"email_sub_weekly_report_description": "Weekly updates about how much you earned on Prime Opinion",
	"email_sub_account_updates_description": "Updates about your account such as reaching a new level or winning points on the leaderboard",
	"email_sub_survey_invitations_description": "Weekly Invitations to the best and highest-paying surveys",
	"email_subs_updated": "Preferences has been successfully updated",
	"email_subs_not_updated": "Preferences updating error :(",
	"profile": "Profile",
	"account": "Account",
	"more": "More",
	"invite_friend_updated": "Invite your friends and earn 10 % forever",
	"prize_draw_description": "Reach a 7 day streak and draw a prize",
	"win_up_points": "Win up to {amount} Points",
	"guaranteed_prize": "Guaranteed prize! Every draw wins!",
	"need_new_password": "Need new password?",
	"unlink_socials": "Changing email will unlink all social accounts",
	"reset_password_sent_message": "We’ve emailed a reset password link to <span class='user-email'>{email}</span>",
	"reset_password_sent_message-details": "Please check your inbox and click on the link to reset your password",
	"reset_password_sent_message-spam": "If you don’t see the email in your inbox, check your spam folder",
	"achievements": "Achievements",
	"achievements_earned": "Achievements earned",
	"unlock_first_achievement": "Unlock the first achievement to see the whole category",
	"value_of_total": "{value} of {total}",
	"claimed": "Claimed",
	"achievement_unlocked": "Achievement Unlocked",
	"go_to_achievements_and_redeem_bonus": "Go to Achievements and redeem your bonus",
	"go_to_achievements": "Go To Achievements",
	"language": "Language",
	"change_language_title": "Change Language",
	"change_language_subtitle": "Available Languages",
	"change_language_message": "The selected language affects how you see Prime Opinion and how we communicate with you",
	"your_reward": "Your reward",
	"thank_you_for_opinion": "Thank you for your opinion!",
	"take_surveys_earn_points": "Take more surveys to earn more Points",
	"take_surveys": "Take Surveys",
	"sounds_great": "Sounds Great!",
	"select_country_language_header": "Select Country and Language",
	"search_country": "Search country",
	"localization_modal_no_items": "There are no available options for",
	"other_options": "Other options",
	"enter_email_password": "Please enter your email to continue",
	"continue": "Continue",
	"login": "Log in",
	"forgot_password": "Forgot password?",
	"sign_up": "Sign up",
	"accept_policy_terms": "I accept the <a href={privacyLink} target='_blank' rel='noopener noreferrer' class='legal-link'>Privacy Policy</a> and <a href={termsLink} target='_blank' rel='noopener noreferrer' class='legal-link'>Terms & Conditions</a>",
	"sign_up_info": "We need your email address and password to save your progress and send you the rewards you redeem. Your data is not given to any third party.",
	"email_verification_warning": "Please click the link in the <span>confirmation email</span>",
	"sent": "Sent",
	"or": "OR",
	"continue_with_provider": "Continue with ",
	"social_account_message": "Looks like you already have an account. </br> Please log in instead.",
	"not_your": "Not your? ",
	"use_another_account": "Use another account",
	"sign_up_google": "You are signing up with Gmail",
	"sign_up_facebook": "You are signing up with Facebook",
	"sign_up_apple": "You are signing up with Apple ID",
	"account_exists": "Account Exists",
	"link_account_message": "Looks like you already have an account. Please log in instead.",
	"set_password": "Set Password",
	"set_password_message": "Please set a password to log in to your account",
	"exchange_rate": "Exchange Rate",
	"blue": "Blue",
	"bronze": "Bronze",
	"silver": "Silver",
	"gold": "Gold",
	"diamond": "Diamond",
	"levels_description_title": "Start taking surveys today and see how far you can go!",
	"levels_current": "Current Level:",
	"live_chat": "Live Chat Support",
	"early_access": "Early access to new feature",
	"upcoming_benefits": "Upcoming benefits",
	"new_levels": "New Levels",
	"benefit_live_chat": "You can now use live chat",
	"benefit_early_access": "Early access to new features",
	"tier_bonus": "Tier Bonus",
	"how_levels_work": "How Levels Work",
	"claim_email": "Please enter your {value} email",
	"paypal_delay_message": "PayPal payments are temporarily taking up to {hours} hours.",
	"no_connection_title": "No Internet Connection",
	"no_connection_message": "It appears that your device is currently not connected to the internet. Please check your network settings and try again.",
	"reload": "Reload",
	"code_required": "Code is required",
	"code_sent": "Code sent!",
	"code_sent_message": "Please check your email and submit the verification code below.",
	"code_sent_question": "Didn’t get the email?",
	"resend_code": "Resend code",
	"not_verified_code": "Please enter the confirmation code we sent yo your email.",
	"or_continue_with": "OR Continue with",
	"login_or_sign_up": "Log In or Sign Up",
	"reward_is_on_way": "Reward is on the way!",
	"confirm_paypal_email": "Confirm Email",
	"paypal_email": "Paypal Email",
	"confirm_paypal_message": "You have not used this PayPal email before. \n Do you want to Proceed?",
	"proceed": "Proceed",
	"refunded": "Refunded",
	"error": "Error",
	"refund": "Refund",
	"password_not_equal": "The value must be equal to the password value",
	"redeem_code": "Redeem Bonus Code",
	"bonus_code": "Bonus Code",
	"enter_code": "Enter code",
	"submit": "Submit",
	"bonus_code_message": "If you have a Bonus Code, enter it below",
	"select_language": "Select language",
	"streak_saved": "Streak Saved",
	"update_dialog_title": "Update Available",
	"update_dialog_message": "Version {version} is available. Would you like to update now?`",
	"app_review": "App Review",
	"app_review_text": "The app might soon ask you for a review. It would mean a lot to us if you could take a few minutes to give us a review. Thank you!",
	"email_to_previous": "We’ve sent an email to your previous email address. Please click the confirmation link in that email to confirm your changes.",
	"confirm_claim_modal_title": "Please confirm it's you!",
	"confirm_claim_modal_message": "Please check <b style='color: var(--text-default)'>{email}</b> for a confirmation link to approve this reward",
	"confirm_via_email": "Confirm via email",
	"translate_modal_header": "Translate",
	"translate_modal_title": "Hi, It looks like you're trying to use Google Translate.",
	"translate_modal_info": "Did you know you can have an even better experience by customizing your language preferences right here?",
	"translate_modal_message": "Go to Account Settings, click on the language and choose your preferred language.",
	"open_account_settings": "Open Account Settings",
	"none": "None",
	"leaderboard_locked": "Leaderboard Locked",
	"highest_rank": "Your highest rank",
	"leaderboard_unlock_target": "Complete {value} more surveys to unlock the leaderboard.",
	"bonus_day_target": "Reach Silver Tier to unlock",
	"bonus_day_select_description": "Select your preferred weekday and enjoy an extra {value} bonus on all surveys",
	"select_day": "Select a day",
	"bonus_day_select_interval": "The minimum period between 2 bonus days is 7 days. If you save this your next bonus day will be: ",
	"monday_full": "Monday",
	"tuesday_full": "Tuesday",
	"wednesday_full": "Wednesday",
	"thursday_full": "Thursday",
	"friday_full": "Friday",
	"saturday_full": "Saturday",
	"bonus_day_unlock_description": "Reach level 11 to unlock the Silver Tier, you'll be able to select your preferred weekday and enjoy an extra {value} bonus on all  surveys.",
	"reward_discount": "Reward Discount",
	"reward_discount_benefit_title": "{dynamicValue} Reward Discount",
	"reward_discount_benefit_description": "You unlocked a {dynamicValue} discount on selected rewards. Go to the rewards selection to check it out.",
	"bonus_day_dynamic": "{dynamicValue} Bonus Day",
	"bonus_day_benefit_description_fixed": "Head to your account page to select a preferred weekday. On that chosen day, your rewards will enjoy a boost of {dynamicValue}",
	"save_discount": "Save {percentage}",
	"you_won_bonus": "You won a {amount} Bonus",
	"prize_bonus_modal_title": "You won a bonus!",
	"prize_bonus_modal_description": "We sent your personal bonus code to your email.",
	"revolut_reward_signup_description": "Sign up on Revolut via Prime Opinion for an exclusive 10% off your first reward claim!",
	"click_to_signup": "Click here to sign up",
	"full_name": "Full Name",
	"tag": "Tag",
	"full_name_required": "Full name required",
	"tag_required": "Tag required",
	"full_name_min_length": "Full name too short",
	"full_name_max_length": "Full name too long",
	"tag_min_length": "Tag too short",
	"tag_max_length": "Tag too long",
	"revolut_claim_data": "Please enter your Revolut <span class='bold'>Full name</span> & <span class='bold'>Revolut tag</span>",
	"revolut_announcement_join": "Join Revolut",
	"confirm_ach_full_name": "Account Holder's Full Name",
	"confirm_ach_full_name_placeholder": "Full name as on your bank account...",
	"confirm_ach_full_name_message": "Your name must be as your bank account. \n Do you want to proceed?",
	"confirm_full_name": "Confirm Full Name",
	"change_name": "Change Name",
	"ask_permissions_modal_header": "Want to earn more?",
	"ask_permissions_modal_title": "Some surveys need additional data to track when you complete them.",
	"ask_permissions_modal_message": "Please give your permission to see more great surveys. After clicking “Select choice”, you will see a modal asking for permission.",
	"ask_permissions_modal_important": "You won't share other personal data by giving your permission",
	"select_choice": "Select choice",
	"offerwalls_title": "Offerwalls",
	"offerwalls_description": "Prime Opinion partners with Offerwall to enable you to earn additional income.",
	"offerwalls_disclaimer": "We don’t control the offers and can't provide support for them. If you require assistance, please contact the offerwall company directly.",
	"offerwalls_new_earning_fixed": "New way to earn even more.",
	"offerwalls_enable_earning": "Prime Opinion Partners with offerwalls to enable you earn more!",
	"offerwalls_new_earning": "New way to earn additional income. Prime Opinion Partners with offerwall to enable you earn more!",
	"offerwalls_reward": "We credited you {coins} Points for completing an offer <b>\"{name}\"</b>",
	"complete": "Complete",
	"ref_card_title": "Earn more by referring",
	"ref_card_teaser": "When someone signs up using your link, you earn a 10% commission on their survey points!",
	"ref_card_button": "How it works?",
	"ref_hiw_step_1_code": "Tell your friends about Prime Opinion and share it on Social Media",
	"ref_hiw_step_2_code": "New users that redeem your bonus code within 24 hours after signing up become your referral and get a 10 % bonus for 24 hours",
	"ref_hiw_step_3_code": "Earn 10 % commission on your friends' income for all surveys they complete. Your friends' income remains the same!",
	"referral_link": "Referral Link",
	"total_claims": "Lifetime Reward Claims:",
	"sorry_modal_header": "We are sorry",
	"sorry_modal_title": "Apologies, we messed up",
	"sorry_modal_description": "Our iOS app occasionally froze over the last 3 days. We are are so sorry! We fixed this! Please use the bonus code <b>iOS3000</b> for an exclusive 30 % bonus as an apology",
	"sorry_modal_action_2": "Update Now",
	"all": "All",
	"popular": "Popular",
	"food": "Food",
	"shopping": "Shopping",
	"gaming": "Gaming",
	"your_referral_link": "Your referral link",
	"copy": "Copy",
	"copy_code": "copy code",
	"use_referral_instead": "Use link instead",
	"unlock": "Unlock",
	"unlock_offerwalls_target": "Complete {target} more surveys to unlock offers",
	"unlock_offerwalls_progress": "{progress} surveys completed",
	"received_points": "You got a {points} Points Bonus!",
	"cashed_out_points": "Cash out from {points} Points",
	"preset_welcome_bonus_description": "You can cash out points anytime for real cash via PayPal or choose from {giftCount}+ gift cards and other options.",
	"new_version_available_header": "New version is available!",
	"new_version_available_title": "It’s time to update",
	"new_version_available_msg_store": "A brand new version of Prime Opinion app is available in the {store}. Please update your app to use all of our amazing features.",
	"new_version_available_btn": "Update",
	"new": "new",
	"offers": "Offers",
	"refer_link_title": "Refer a friend",
	"refer_link_title_subtitle": "Invite your friend and earn {value} points",
	"redeem_link_title": "Redeem Bonus Code",
	"complete_survay_info_text": "Complete {value} more survey(s) to start competing",
	"login_page_title": "Earn cash with paid surveys",
	"million": "{currency}{count} million",
	"earned_by_members": "Earned by Members",
	"new_version_available_title_downloading": "Update in progress...",
	"new_version_available_msg_downloading": "App is updating, please wait a bit",
	"new_version_available_title_installing": "Updates are being installed",
	"new_version_available_msg_installing": "Please wait a moment while we enhance your experience",
	"new_version_available_title_installed": "The updates have been installed",
	"new_version_available_msg_installed": "Please click the button below to restart and apply the changes",
	"new_version_available_btn_installed": "Apply changes",
	"new_version_available_title_failed": "The update could not be installed",
	"new_version_available_msg_failed": "Please update via the {store}",
	"new_version_available_btn_failed": "Go to {store}",
	"new_version_available_success": "Prime Opinion was successfully updated",
	"copied_into_clipboard": "Copied into clipboard",
	"partners_not_found": "No partners found",
	"well_done_v2": "Well Done !",
	"offerwalls_well_done_modal_title": "You completed offer \"{name}\" for {points} Points.",
	"offerwalls_well_done_modal_teaser": "Our partner has put a {days} day verification period on this reward. Points will be credited to your account within {days} days.",
	"pending_points": "Pending Points",
	"pending_until": "Pending until",
	"total_pending_points": "Total Pending Points",
  "preset_welcome_bonus_title": "Welcome to Prime Opinion",
  "annoucement_v2_head": "Welcome to Prime Opinion {version}",
  "annoucement_v2_title": "We are thrilled to announce the launch of  Prime Opinion {version}!",
  "annoucement_v2_subtitle": "We've been hard at work to bring you an enhanced experience, and we're excited to share the new features and improvements:",
  "annoucement_v2_btn": "Open Prime {version}",
  "annoucement_v2_list_1_title": "Updated Design",
  "annoucement_v2_list_1_teaser": "Enjoy a sleek, modern interface.",
  "annoucement_v2_list_2_title": "Higher Payouts",
  "annoucement_v2_list_2_teaser": " Over the past few months, we've negotiated even better payouts.",
  "annoucement_v2_list_3_title": "More and Better Surveys",
  "annoucement_v2_list_3_teaser": "We've added a wider variety of surveys from more clients.",
  "annoucement_v2_list_4_title": "Improved Survey Quality",
  "annoucement_v2_list_4_teaser": "This means fewer late screenouts and disqualifications, so you can complete more surveys successfully.",
  "username_update_success_message": "Username has been successfully updated",
  "timezone_update_success_message": "Timezone has been successfully updated",
	"cashback_announcement_header": "Get {percentage}% Back on Your Payouts!",
	"cashback_announcement_title": "Get {percentage}% back on all your payouts.",
	"cashback_announcement_description": "Redeem your points for any reward and Instantly receive {percentage}% back.\nThis offer won’t last long, so take advantage of it today!",
	"cashback_announcement_end_date": "Only until {expiry}",
	"cashback_notification_header": "You got {percentage}% back.",
	"cashback_notification_title": "You've Received {percentage}% Back in Points!",
	"cashback_notification_description": "As part of our special promotion, we've credited {percentage}% of the points you used back to your account!",
	"rewards_cashback_subtitle": "Until {expiry}, we'll give you {percentage}% back in points on every reward you redeem!",
	"cashback_transaction": "Cashback",
	"cashback_deducted_transaction": "Deducted Cashback",
  "no_results": "No Results",
  "leaderboard_ends_in": "Ends in",
	"leaderboard_start_in_3": "Next Leaderboard starting in",
  "your_rank": "Your Ranking",
  "earn_points_to_win_more": "Earn {value} points to win  <span> +{win}  points </span> more",
  "history_page_title": "Rewards & Transactions",
	"select_theme": "Select Theme",
	"theme_note": "Please note that survey pages will not be available in dark mode.",
	"light": "Light",
	"dark": "Dark",
	"system": "System",
  "offers_activities": "Offers Activities",
  "games": "Games",
  "pending": "Pending",
  "total_pending_amount": "Total Pending Amount",
  "money_earnd_from_games": "Money earned from playing games.",
  "cancel": "Cancel",
	"close": "Close",
	"close_confirm": "Are you sure you want to close this window?",
  "you_earned": "You earned",
  "we_credit_you_currency": "We credited {value} for completing “{name}” offer.",
  "game": "Game",
  "offers_activities": "Offers Activities",
  "allow_location_title": "Allow location access",
  "allow_location_description": "In order to show you more relevant surveys, we would like to access your location."
}
