<script lang="ts" setup>
import { computed } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = defineProps<{
	tierKey: string;
	size: string;
	level?: string | number;
	levelSize?: string;
	minWidth?: string;
	rounded?: string;
}>();

const tierColor = computed(() => `var(--level-circle-${props.tierKey}`);
const levelSize = computed(() =>
	props.levelSize ? props.levelSize : '0.875rem'
);
</script>

<template>
	<div
		class="tier-circle"
		:class="[$attrs.class]"
		:style="{
			width: `${size}`,
			height: `${size}`,
			minWidth: `${minWidth || size}`,
			borderRadius: rounded || '50%',
		}"
	>
		<PrimeText v-if="level" class="tier-level" color="white" weight="600">
			{{ level }}
		</PrimeText>
		<slot v-else />
	</div>
</template>

<style lang="scss" scoped>
.tier-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	background: v-bind(tierColor);
	box-shadow: inset 0px 2.81px 8.42px rgba(0, 0, 0, 0.34);

	& .tier-level {
		font-size: v-bind(levelSize) !important;
		line-height: 1;
	}
}
</style>
