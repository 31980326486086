<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import UserDashboard from '@/views/surveys/components/user-dashboard/UserDashboard.vue';
import ReferralsCard from '@/views/referrals/components/ReferralsCard.vue';
import {
	PrimeDivider,
	PrimeSidebar,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const authStore = useAuthStore();
const { isLaptop, isTablet } = useScreenSize();

const { isAuthorized } = storeToRefs(authStore);
</script>

<template>
	<PrimeSidebar
		v-if="!isTablet"
		:width="isLaptop ? '15rem' : '18.75rem'"
		position="right"
	>
		<UserDashboard v-if="isAuthorized" />
		<PrimeDivider
			v-if="isAuthorized"
			top-gap="1.5rem"
			bottom-gap="1.5rem"
			color="grey-400"
		/>
		<ReferralsCard />
	</PrimeSidebar>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.dashboard {
	margin-bottom: 1.5rem;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid var(--grey-400);
}
</style>
