import { defineStore } from 'pinia';
import type { Notification, Announcement, ErrorResponse } from '@/types';
import {
	getAnnouncements,
	getNotifications,
	setAnnouncementAsRead,
	setNotificationAsRead,
} from '@/api';
import { getErrorResponse } from '@/utils/helpers';

interface MessagesState {
	notifications: Notification[];
	announcements: Announcement[];
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
}

export const useMessagesStore = defineStore('messages', {
	state: (): MessagesState => ({
		notifications: [],
		announcements: [],
		loading: false,
		initLoading: false,
		error: null,
	}),

	getters: {
		notificationsList: (state) => state.notifications || [],
		announcementsList: (state) => state.announcements || [],
	},

	actions: {
		async fetchUserNotifications() {
			try {
				this.notifications = await getNotifications();
			} catch (e) {
				this.error = getErrorResponse(e);
			}
		},
		async fetchUserAnnouncements() {
			try {
				this.announcements = await getAnnouncements();
			} catch (e) {
				this.error = getErrorResponse(e);
			}
		},
		removeNotification(id: string) {
			const index = this.notifications.findIndex(
				(notification) => notification.id === id
			);
			if (index !== -1) {
				this.notifications.splice(index, 1);
			}
		},
		removeAnnouncement(uuid: string) {
			const index = this.announcements.findIndex(
				(announcement) => announcement.uuid === uuid
			);
			if (index !== -1) {
				this.announcements.splice(index, 1);
			}
		},
		async readNotification(id: string) {
			await setNotificationAsRead(id);
			this.removeNotification(id);
		},
		async readAnnouncement(uuid: string) {
			await setAnnouncementAsRead(uuid);
			this.removeAnnouncement(uuid);
		},
	},
});
