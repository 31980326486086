import API from '@/api';

export const checkVersion = async (
	bundleVersion: string,
	nativeAppVersion: string
): Promise<{ capgo_enabled: number; native_update: boolean }> => {
	//?
	const { data } = await API.get<{
		capgo_enabled: number;
		native_update: boolean;
	}>('/api/mobile/version-info', {
		params: {
			bundle_v: bundleVersion,
			native_app_v: nativeAppVersion,
			authRequired: false,
		},
	});

	return data;
};
