<script lang="ts" setup>
import { ref } from 'vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useRouter } from 'vue-router';
import {
	CHECK_IT_OUT,
	CONGRATS,
	KEEP_IT_UP,
	LEADERBOARD_SCORED_MESSAGE_WEEKLY,
	LEADERBOARD_SCORED_TITLE,
	LEADERBOARD_UNLOCKED_MESSAGE,
	LEADERBOARD_UNLOCKED_TITLE,
	LEADERBOARD_WON_MESSAGE,
	LEADERBOARD_WON_TITLE_WEEKLY,
	WELL_DONE,
} from '@/locales/constants';
import { LeaderboardTypes } from '@/enums';
import ChangeUsernameForm from '@/components/forms/ChangeUsernameForm.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import { PiTrophyDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeText,
	PrimeButton,
	PrimeDivider,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

type Options = {
	type: LeaderboardTypes;
	rank?: number;
	coins?: number;
};

const router = useRouter();
const { username } = storeToRefs(useUserStore());

defineProps<{
	options: Options;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const isDisabled = ref(false);

const goToleaderboard = () => {
	isDisabled.value = true;
	router.push({ name: 'leaderboard' });
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent id="leaderboard-modal">
		<template #header>
			<template v-if="options.type === LeaderboardTypes.LOCKED">
				{{ $t(WELL_DONE) }}
			</template>
			<template v-else>{{ $t(CONGRATS) }}</template>
		</template>
		<template #icon>
			<PiTrophyDuotoneThin color="base-primary" size="100%" />
		</template>
		<template #title>
			<PrimeText
				v-if="options.type === LeaderboardTypes.UNLOCKED"
				tag="div"
				weight="500"
			>
				{{ $t(LEADERBOARD_UNLOCKED_TITLE) }}
			</PrimeText>
			<PrimeText
				v-if="options.type === LeaderboardTypes.SCORED"
				tag="div"
				weight="500"
			>
				{{ $t(LEADERBOARD_SCORED_TITLE) }}
			</PrimeText>
			<PrimeText
				v-if="options.type === LeaderboardTypes.LOCKED"
				tag="div"
				weight="500"
			>
				<span
					v-html="
						`${$t(LEADERBOARD_WON_TITLE_WEEKLY, {
							place: options.rank,
						})}`
					"
				/>
			</PrimeText>
		</template>
		<template #message>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED && !username">
				{{ $t(LEADERBOARD_UNLOCKED_MESSAGE) }}
			</template>

			<template v-if="options.type === LeaderboardTypes.SCORED">
				<PrimeDivider top-gap="1.375rem" bottom-gap="1.375rem" />
				<PrimeText tag="div" color="primary" class="message-scored">
					{{ $t(LEADERBOARD_SCORED_MESSAGE_WEEKLY) }}
				</PrimeText>
			</template>

			<PrimeText
				v-if="options.type === LeaderboardTypes.LOCKED"
				tag="div"
				weight="600"
				color="primary"
				class="message-locked"
			>
				{{
					$t(LEADERBOARD_WON_MESSAGE, {
						value: localizePoints(options.coins || 0),
					})
				}}
			</PrimeText>
		</template>
		<template #actions>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED">
				<ChangeUsernameForm v-if="!username" :handler="goToleaderboard" />
				<PrimeButton
					v-else
					type="gradient"
					full-width
					:label="$t(CHECK_IT_OUT)"
					:disabled="isDisabled"
					@click="goToleaderboard"
				/>
			</template>
			<template v-if="options.type === LeaderboardTypes.SCORED">
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(CHECK_IT_OUT)"
					:disabled="isDisabled"
					@click="goToleaderboard"
				/>
			</template>
			<PrimeButton
				v-if="options.type === LeaderboardTypes.LOCKED"
				type="text"
				variant="grey"
				:label="$t(KEEP_IT_UP)"
				:disabled="isDisabled"
				@click="goToleaderboard"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
#leaderboard-modal {
	.content-title {
		& > div > span > span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 2rem;
			height: 2rem;
			background-color: var(--base-primary-05);
			border-radius: 50%;
		}
	}

	.content-message {
		color: var(--grey-700);
		max-width: 100%;
		width: 100%;

		.message-locked {
			margin-top: 1.375rem;
		}
	}

	.content-actions {
		margin-top: 1.375rem;

		.content-actions-button {
			margin-top: 1rem;
		}
	}
	.bottom-wrapper {
		display: flex;
		justify-content: center;
	}
}
</style>
