<template>
	<svg
		width="96"
		height="96"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="WifiX" clip-path="url(#clip0_386_4026)">
			<path
				id="Vector"
				d="M48 80.25C50.0711 80.25 51.75 78.5711 51.75 76.5C51.75 74.4289 50.0711 72.75 48 72.75C45.9289 72.75 44.25 74.4289 44.25 76.5C44.25 78.5711 45.9289 80.25 48 80.25Z"
				fill="black"
			/>

			<path
				id="Vector_2"
				d="M84 21L66 39"
				stroke="black"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>

			<path
				id="Vector_3"
				d="M84 39L66 21"
				stroke="black"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>

			<path
				id="Vector_4"
				d="M63 61.875C58.6412 58.7045 53.3899 56.9966 48 56.9966C42.6101 56.9966 37.3588 58.7045 33 61.875"
				stroke="black"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>

			<path
				id="Vector_5"
				d="M54 21.2888C52.0275 21.0963 50.0275 21 48 21C33.7748 20.9782 19.9857 25.9091 9 34.9463"
				stroke="black"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>

			<path
				id="Vector_6"
				d="M54 39.4087C52.0117 39.1357 50.007 38.9992 48 39C38.1985 38.9802 28.6801 42.2853 21 48.375"
				stroke="black"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
</template>
