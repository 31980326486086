import { computed, inject, type InjectionKey, provide, ref, watch } from 'vue';
import { cidStorage, maidStorage, sslhStorage } from '@/utils/local-storage';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { COINS } from '@/locales/constants';
import { getLanguage } from '@/utils/helpers';
import { storeToRefs } from 'pinia';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import type { IntegrationModules } from '@/types';
import { usePostMessageHandler } from '@/utils/composables/usePostMessageHandler';
import { PostMessages, ScriptRenderStatuses } from '@/enums';

export const primeSurveysInjection: InjectionKey<
	ReturnType<typeof providePrimeSurveys>
> = Symbol();

export const usePrimeSurveysIntegration = () => inject(primeSurveysInjection)!;

export const providePrimeSurveys = () => {
	const { platform } = useMobileApp();
	const { localizationCookie } = useAppCookies();
	const i18n = useI18n();
	const userStore = useUserStore();
	const authStore = useAuthStore();

	const { userId, userHash, source } = storeToRefs(userStore);
	const { getAdminId } = storeToRefs(authStore);

	const language = ref(getLanguage(localizationCookie));
	const appId = ref(import.meta.env.VITE_PS_APPID);

	const integrationScriptLoading = ref(true);

	const scriptRenderStatus = ref<ScriptRenderStatuses>(
		ScriptRenderStatuses.INIT
	);

	usePostMessageHandler((event: MessageEvent) => {
		if (event.data.message === PostMessages.SCRIPT_STATUS) {
			scriptRenderStatus.value = event.data.status;
		}
	});

	const isRenderInit = computed(
		() => scriptRenderStatus.value === ScriptRenderStatuses.INIT
	);
	const isRenderFinished = computed(
		() => scriptRenderStatus.value === ScriptRenderStatuses.SUCCESS
	);

	const renderPrimeSurveys = (
		container: string,
		module: IntegrationModules = 'surveys',
		moduleTitle?: string,
		tasks: string = 'surveys'
	): (() => void) =>
		onUserId(() => {
			const props = {
				container,
				module,
				moduleTitle,
				tasks,
				appId: appId.value,
				userId: userId.value,
				language: language.value || 'en',
				clickId: cidStorage.value,
				sHash: userHash.value,
				currency: i18n.t(COINS),
				source: source.value,
				platform: platform.value,
				adminId: getAdminId.value,
				maid: maidStorage.value,
				// TODO: remove it when PS app will be updated
				isFloating: true,
				sslh: sslhStorage.value,
				loader: false,
			};

			// Fallback data for PS script init method
			localStorage.setItem(
				'publisher',
				JSON.stringify({
					uuid: userId.value,
					sHash: userHash.value,
					appId: appId.value,
				})
			);
			try {
				window.PrimeSurveys('render', props);
			} catch (error) {
				throw new Error(
					'PrimeSurveys render fails, PrimeSurveys is not defined'
				);
			}

			integrationScriptLoading.value = false;

			if (cidStorage.value) {
				cidStorage.value = '';
			}

			if (sslhStorage.value) {
				sslhStorage.value = '';
			}
		});

	const onUserId = (cb: () => void) =>
		watch(
			userId,
			(userIdValue) => {
				if (userIdValue) {
					const interval = setInterval(() => {
						if (
							typeof window.PrimeSurveys === 'function' &&
							appId.value &&
							userHash.value
						) {
							clearInterval(interval);
							cb();
						}
					}, 50);
				}
			},
			{ immediate: true }
		);

	const unmountPrimeSurveys = () => {
		if (window.PrimeSurveys !== undefined) {
			window.PrimeSurveys('unmount');
		} else {
			throw new Error(
				'PrimeSurveys unmount fails, PrimeSurveys is not defined'
			);
		}
		scriptRenderStatus.value = ScriptRenderStatuses.INIT;
	};

	const providedData = {
		renderPrimeSurveys,
		unmountPrimeSurveys,
		integrationScriptLoading,
		scriptRenderStatus,
		isRenderFinished,
		isRenderInit,
	};

	provide(primeSurveysInjection, providedData);

	return providedData;
};
