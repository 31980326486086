<script setup lang="ts">
import { computed } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { localizePoints, toDate } from '@/utils/helpers';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { PENDING_UNTIL, POINTS } from '@/locales/constants';
import type { OfferwallPending } from '@/types';

defineProps<{
	data: OfferwallPending;
}>();

const { localizationCookie } = useAppCookies();
const localization = computed(() => localizationCookie || 'en-US');
</script>

<template>
	<div class="offerwalls-pending">
		<div class="offerwalls-pending-left">
			<PrimeText tag="h4" size="sm" weight="500">
				{{ data.offer_name }}
			</PrimeText>
			<div class="offerwalls-pending-date">
				<PrimeText tag="p" size="xs" weight="500" color="grey-700">
					{{ $t(PENDING_UNTIL) }}:
				</PrimeText>
				<PrimeText tag="p" size="xs" weight="500">
					{{ toDate(data.pending_until, localization) }}
				</PrimeText>
			</div>
		</div>
		<PrimeText tag="p" size="sm" weight="500" class="offerwalls-pending-points">
			{{ localizePoints(data.coin_value) }} {{ $t(POINTS) }}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.offerwalls-pending {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.625rem 0;
	border-bottom: 1px solid var(--grey-200);
	border-top: 1px solid var(--grey-200);
}
.offerwalls-pending:first-child {
	border-top: 1px solid transparent;
}
.offerwalls-pending-left {
	display: flex;
	flex-direction: column;
	padding-right: 1rem;
	align-items: flex-start;
	text-align: left;
}
.offerwalls-pending-date {
	display: flex;
	flex-wrap: wrap;
	gap: 0.188rem;
}
.offerwalls-pending-points {
	flex: none;
}
</style>
