<script setup lang="ts">
import {
	COPIED_INTO_CLIPBOARD,
	COPY,
	REFERRAL_LINK,
	YOUR_REFERRAL_LINK,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import NoteContentWrapper from '@/components/common/NoteContentWrapper.vue';
import { storeToRefs } from 'pinia';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';

const { refLink } = storeToRefs(useUserStore());
const { t } = useI18n();

const copyToClipboard = async () => {
	await navigator.clipboard.writeText(refLink.value);
	notify({
		body: `${t(COPIED_INTO_CLIPBOARD)}: ${refLink.value}`,
	});
};
</script>

<template>
	<div class="copy-referrals-link-modal">
		<ModalCommonContent>
			<template #header>{{ $t(REFERRAL_LINK) }}</template>
			<template #title>
				{{ $t(YOUR_REFERRAL_LINK) }}
			</template>
			<template #default>
				<NoteContentWrapper
					border-type="dashed"
					class="copy-referrals-link-content"
				>
					<div class="link-wrapper">
						<PrimeText color="primary" size="sm">
							{{ refLink }}
						</PrimeText>
						<PrimeButton
							type="gradient"
							full-width
							:label="$t(COPY)"
							@click="copyToClipboard"
						/>
					</div>
				</NoteContentWrapper>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.copy-referrals-link-modal {
	:deep(.modal-common-content) {
		& .copy-referrals-link-content {
			width: 100%;
			margin-bottom: 0;

			& .link-wrapper {
				width: 100%;
				gap: 0.5rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
}

:deep(.p-modal-content) {
	.modal-common-content.p-without-border-bottom & {
		padding-bottom: 1.5rem !important;
	}
}
</style>
