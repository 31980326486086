import { TierBenefits } from '@/enums';
import {
	BONUS_DAY,
	EARLY_ACCESS,
	LIVE_CHAT,
	REWARD_DISCOUNT,
} from '@/locales/constants';

export const tiersBenefits = {
	[TierBenefits.LIVE_CHAT]: LIVE_CHAT,
	[TierBenefits.EARLY_ACCESS]: EARLY_ACCESS,
	[TierBenefits.BONUS_DAY]: BONUS_DAY,
	[TierBenefits.REWARD_DISCOUNT]: REWARD_DISCOUNT,
};
