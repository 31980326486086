import type { Localization } from '@/types';

export const localizations: Localization[] = [
	{
		code: 'en-us',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'us',
		iso: 'en-US',
	},
	{
		code: 'es-us',
		file: 'es.json',
		language: 'es',
		default: false,
		country: 'us',
		iso: 'es-US',
	},
	{
		code: 'en-gb',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'gb',
		iso: 'en-GB',
	},
	{
		code: 'en-ca',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'ca',
		iso: 'en-CA',
	},
	{
		code: 'fr-ca',
		file: 'fr.json',
		language: 'fr',
		default: false,
		country: 'ca',
		iso: 'fr-CA',
	},
	{
		code: 'en-au',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'au',
		iso: 'en-AU',
	},
	{
		code: 'de-de',
		file: 'de.json',
		language: 'de',
		default: true,
		country: 'de',
		iso: 'de-DE',
	},
	{
		code: 'fr-fr',
		file: 'fr.json',
		language: 'fr',
		default: true,
		country: 'fr',
		iso: 'fr-FR',
	},
	{
		code: 'nl-nl',
		file: 'nl.json',
		language: 'nl',
		default: true,
		country: 'nl',
		iso: 'nl-NL',
	},
	{
		code: 'es-es',
		file: 'es.json',
		language: 'es',
		default: true,
		country: 'es',
		iso: 'es-ES',
	},
	{
		code: 'nl-be',
		file: 'nl.json',
		language: 'nl',
		default: true,
		country: 'be',
		iso: 'nl-BE',
	},
	{
		code: 'fr-be',
		file: 'fr.json',
		language: 'fr',
		default: false,
		country: 'be',
		iso: 'fr-BE',
	},
	{
		code: 'es-mx',
		file: 'es.json',
		language: 'es',
		default: true,
		country: 'mx',
		iso: 'es-MX',
	},
	{
		code: 'it-it',
		file: 'it.json',
		language: 'it',
		default: true,
		country: 'it',
		iso: 'it-IT',
	},
	{
		code: 'en-in',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'in',
		iso: 'en-IN',
	},
	{
		code: 'pt-br',
		file: 'pt.json',
		language: 'pt',
		default: true,
		country: 'br',
		iso: 'pt-BR',
	},
	{
		code: 'en-nz',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'nz',
		iso: 'en-NZ',
	},
	{
		code: 'en-my',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'my',
		iso: 'en-MY',
	},
	{
		code: 'ms-my',
		file: 'ms.json',
		language: 'ms',
		default: false,
		country: 'my',
		iso: 'ms-MY',
	},
	{
		code: 'ko-kr',
		file: 'ko.json',
		language: 'ko',
		default: true,
		country: 'kr',
		iso: 'ko-KR',
	},
	{
		code: 'en-sg',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'sg',
		iso: 'en-SG',
	},
	{
		code: 'zh-sg',
		file: 'zh.json',
		language: 'zh',
		default: false,
		country: 'sg',
		iso: 'zh-SG',
	},
	{
		code: 'en-ie',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'ie',
		iso: 'en-IE',
	},
	{
		code: 'sv-se',
		file: 'sv.json',
		language: 'sv',
		default: true,
		country: 'se',
		iso: 'sv-SE',
	},
	{
		code: 'de-ch',
		file: 'de.json',
		language: 'de',
		default: true,
		country: 'ch',
		iso: 'de-CH',
	},
	{
		code: 'it-ch',
		file: 'it.json',
		language: 'it',
		default: false,
		country: 'ch',
		iso: 'it-CH',
	},
	{
		code: 'fr-ch',
		file: 'fr.json',
		language: 'fr',
		default: false,
		country: 'ch',
		iso: 'fr-CH',
	},
	{
		code: 'th-th',
		file: 'th.json',
		language: 'th',
		default: true,
		country: 'th',
		iso: 'th-TH',
	},
	{
		code: 'pl-pl',
		file: 'pl.json',
		language: 'pl',
		default: true,
		country: 'pl',
		iso: 'pl-PL',
	},
	{
		code: 'de-at',
		file: 'de.json',
		language: 'de',
		default: true,
		country: 'at',
		iso: 'de-AT',
	},
	{
		code: 'ja-jp',
		file: 'ja.json',
		language: 'ja',
		default: true,
		country: 'jp',
		iso: 'ja-JP',
	},
	{
		code: 'id-id',
		file: 'id.json',
		language: 'id',
		default: true,
		country: 'id',
		iso: 'id-ID',
	},
	{
		code: 'ar-sa',
		file: 'ar.json',
		language: 'ar',
		default: true,
		country: 'sa',
		iso: 'ar-SA',
	},
	{
		code: 'es-cl',
		file: 'es.json',
		language: 'es',
		default: true,
		country: 'cl',
		iso: 'es-CL',
	},
	{
		code: 'en-ae',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'ae',
		iso: 'en-AE',
	},
	{
		code: 'ar-ae',
		file: 'ar.json',
		language: 'ar',
		default: false,
		country: 'ae',
		iso: 'ar-AE',
	},
	{
		code: 'en-ph',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'ph',
		iso: 'en-PH',
	},
	{
		code: 'fil-ph',
		file: 'fil.json',
		language: 'fil',
		default: false,
		country: 'ph',
		iso: 'fil-PH',
	},
	{
		code: 'en-za',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'za',
		iso: 'en-ZA',
	},
	{
		code: 'da-dk',
		file: 'da.json',
		language: 'da',
		default: true,
		country: 'dk',
		iso: 'da-DK',
	},
	{
		code: 'no-no',
		file: 'no.json',
		language: 'no',
		default: true,
		country: 'no',
		iso: 'NO-NO',
	},
	{
		code: 'es-ar',
		file: 'es.json',
		language: 'es',
		default: true,
		country: 'ar',
		iso: 'es-AR',
	},
	{
		code: 'en-hk',
		file: 'en.json',
		language: 'en',
		default: true,
		country: 'hk',
		iso: 'en-HK',
	},
	{
		code: 'zh-hk',
		file: 'zh.json',
		language: 'zh',
		default: false,
		country: 'hk',
		iso: 'zh-HK',
	},
	{
		code: 'vi-vn',
		file: 'vi.json',
		language: 'vi',
		default: true,
		country: 'vn',
		iso: 'vi-VN',
	},
	{
		code: 'es-co',
		file: 'es.json',
		language: 'es',
		default: true,
		country: 'co',
		iso: 'es-CO',
	},
	{
		code: 'tr-tr',
		file: 'tr.json',
		language: 'tr',
		default: true,
		country: 'tr',
		iso: 'tr-TR',
	},
	{
		code: 'ro-ro',
		file: 'ro.json',
		language: 'ro',
		default: true,
		country: 'ro',
		iso: 'ro-RO',
	},
	{
		code: 'fi-fi',
		file: 'fi.json',
		language: 'fi',
		default: true,
		country: 'fi',
		iso: 'fi-FI',
	},
	{
		code: 'cs-cz',
		file: 'cs.json',
		language: 'cs',
		default: true,
		country: 'cz',
		iso: 'cs-CZ',
	},
	{
		code: 'pt-pt',
		file: 'pt.json',
		language: 'pt',
		default: true,
		country: 'pt',
		iso: 'pt-PT',
	},
];

export const supportedCountries: string[] = [
	...new Set(localizations.map((locale) => locale.country)),
];

export const supportedLocales: string[] = localizations.map(
	(locale) => locale.code
);

export default {
	supportedLocales,
	supportedCountries,
};
