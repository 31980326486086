<script setup lang="ts">
import { computed } from 'vue';
import { usePrtners } from '@/views/surveys/composables/partners';

const { partners_card } = usePrtners();

const firstRow = computed(() => partners_card.slice(0, 5));
const secondRow = computed(() => partners_card.slice(5));
</script>

<template>
	<div class="partners-list">
		<div class="row-first">
			<img
				v-for="partner in firstRow"
				:key="partner.name"
				:src="partner.icon"
				:alt="partner.name"
			/>
		</div>
		<span class="row-second">
			<img
				v-for="partner in secondRow"
				:key="partner.name"
				:src="partner.icon"
				:alt="partner.name"
			/>
		</span>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.partners-list {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	height: auto;

	.row-first {
		display: flex;
		justify-content: center;
		gap: 0.375rem;
	}

	.row-second {
		display: flex;
		justify-content: center;
		gap: 0.5rem;
	}

	img {
		width: 44px;
		height: auto;
		filter: blur(1.8px);
	}

	@include breakpoint(tablet) {
		img {
			width: 35px;
		}
	}

	@include breakpoint(laptop) {
		img {
			width: 44px;
		}
	}
}
</style>
