import API from '@/api';
import type {
	ClaimedReward,
	MessageResponse,
	RewardsResponse,
	StatusResponse,
	TotalClaims,
} from '@/types';

export const getRewards = async (): Promise<RewardsResponse> => {
	const { data } = await API.get<RewardsResponse>('/api/rewards');
	return data;
};

export const selectReward = async (optionId: number): Promise<number> => {
	const { status } = await API.post('/api/rewards/select', {
		reward_option_id: optionId,
	});
	return status;
};

export const getRewardsHistory = async (): Promise<ClaimedReward[]> => {
	const { data } = await API.get<{
		claims: ClaimedReward[];
		count: number;
	}>('/api/rewards/history');

	return data.claims;
};

export const claimReward = async (
	params?: Record<string, string>
): Promise<MessageResponse & StatusResponse> => {
	const { data, status } = await API.get<MessageResponse>(
		`/api/rewards/claim`,
		{
			params: {
				...(params !== undefined && params),
			},
		}
	);

	return {
		message: data.message,
		status,
	};
};

export const fetchTotalRewardsStats = async (): Promise<TotalClaims> => {
	const { data } = await API.get<TotalClaims>('/api/rewards/stats');
	return data;
};
