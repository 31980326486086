import api from '@/api';
import type { MessageResponse } from '@/types';

export const redeemBonusCode = async (
	code: string
): Promise<{
	data: MessageResponse;
	status: number;
}> => {
	const { data, status } = await api.post<MessageResponse>('/api/bonus-code', {
		code,
	});

	return { data, status };
};
