<script setup lang="ts">
import { onMounted } from 'vue';
import { usePrimeChatIntegration } from '@/utils/composables/useIntegrationChatRender';

const CHAT_CONTAINER_ID = 'prime-chat';
const { renderPrimeChat } = usePrimeChatIntegration();

onMounted(() => {
	renderPrimeChat(`#${CHAT_CONTAINER_ID}`);
});
</script>

<template>
	<div :id="CHAT_CONTAINER_ID" />
</template>
