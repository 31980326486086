import type { PartnersCard } from '@/types';
import walmart from '@/assets/partners/wallmartCard.svg';
import venmo from '@/assets/partners/VenmoCard.svg';
import paypal from '@/assets/partners/PP-Card.svg';
import airbnb from '@/assets/partners/AirbnbCard.svg';
import amazon from '@/assets/partners/AmazonCard.svg';
import ebay from '@/assets/partners/EbayCard.svg';
import spotify from '@/assets/partners/SpotifyCard.svg';
import revolut from '@/assets/partners/RevolutCard.svg';
import subway from '@/assets/partners/SubwayCard.svg';

export const usePrtners = (): { partners_card: PartnersCard } => {
	return {
		partners_card: [
			{ name: 'walmart', icon: walmart },
			{ name: 'paypal', icon: paypal },
			{ name: 'venmo', icon: venmo },
			{ name: 'revolut', icon: revolut },
			{ name: 'subway', icon: subway },
			{ name: 'amazon', icon: amazon },
			{ name: 'spotify', icon: spotify },
			{ name: 'airbnb', icon: airbnb },
			{ name: 'ebay', icon: ebay },
		],
	};
};
