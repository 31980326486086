import API from '@/api';
import type { BonusResponse, MessageResponse } from '@/types';

export const getWelcomeBonuses = async (): Promise<BonusResponse | null> => {
	const { data, status } = await API.get<BonusResponse>('/api/v2/bonus');
	return status === 200 ? data : null;
};

export const selectWelcomeBonus = async (): Promise<MessageResponse> => {
	const { data, status } = await API.post<MessageResponse>('/api/v2/bonus');
	return { ...data, status };
};
