<script lang="ts" setup>
import {
	TRANSLATE_MODAL_TITLE,
	TRANSLATE_MODAL_HEADER,
	TRANSLATE_MODAL_INFO,
	TRANSLATE_MODAL_MESSAGE,
	OPEN_ACCOUNT_SETTINGS,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiTranslateDuotone } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRouter } from 'vue-router';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';

const router = useRouter();
const { shiftModal } = useModalStorage();

const closeModal = () => {
	router.push({ name: 'settings' });
	shiftModal();
};
</script>

<template>
	<div class="leaderboard-weekly-modal">
		<ModalCommonContent>
			<template #header>{{ $t(TRANSLATE_MODAL_HEADER) }}</template>
			<template #icon>
				<PiTranslateDuotone color="base-primary" size="100%" />
			</template>
			<template #title>
				<PrimeText tag="div" weight="500">
					{{ $t(TRANSLATE_MODAL_TITLE) }}
				</PrimeText>
			</template>
			<template #description>
				<PrimeText tag="div" size="sm" color="grey-700">
					{{ $t(TRANSLATE_MODAL_INFO) }}
				</PrimeText>
			</template>
			<template #message>
				<PrimeText tag="div" size="sm" weight="500">
					{{ $t(TRANSLATE_MODAL_MESSAGE) }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(OPEN_ACCOUNT_SETTINGS)"
					@click="closeModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>
