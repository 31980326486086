import {
	BENEFIT_EARLY_ACCESS,
	BENEFIT_LIVE_CHAT,
	BONUS_DAY,
	BONUS_DAY_DESCRIPTION_BENEFIT,
	REWARD_DISCOUNT_BENEFIT_DESCRIPTION,
	REWARD_DISCOUNT_BENEFIT_TITLE,
} from '@/locales/constants';
import {
	PiChatsDuotone,
	PiCrownDuotoneThin,
	PiPercentDuotone,
	PiRocketLaunchDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import type { Component } from 'vue';

export enum Benefits {
	LIVE_CHAT = 'live_chat',
	EARLY_ACCESS = 'early_access',
	BONUS_DAY = 'bonus_day',
	REWARD_DISCOUNT = 'reward_discount',
}

export const benefitsContent: Record<
	Benefits,
	{ icon: Component; text: string; details?: string }
> = {
	[Benefits.LIVE_CHAT]: {
		icon: PiChatsDuotone,
		text: BENEFIT_LIVE_CHAT,
	},
	[Benefits.EARLY_ACCESS]: {
		icon: PiCrownDuotoneThin,
		text: BENEFIT_EARLY_ACCESS,
	},
	[Benefits.BONUS_DAY]: {
		icon: PiRocketLaunchDuotone,
		text: BONUS_DAY,
		details: BONUS_DAY_DESCRIPTION_BENEFIT,
	},
	[Benefits.REWARD_DISCOUNT]: {
		icon: PiPercentDuotone,
		text: REWARD_DISCOUNT_BENEFIT_TITLE,
		details: REWARD_DISCOUNT_BENEFIT_DESCRIPTION,
	},
};
