import { useLocalStorage } from '@vueuse/core';
import { ColorModes } from '@/enums';

export const cidStorage = useLocalStorage<string>('cid', '');
export const aIDStorage = useLocalStorage<string>('a-id', null);
export const platformStorage = useLocalStorage<string>('platform', '');

export const deviceStorage = useLocalStorage<string>('device', '');
export const oneClickSurveyRewardStorage = useLocalStorage<string>(
	'one-click-reward',
	''
);
export const signatureLoginActionStorage = useLocalStorage<string>(
	'signature-login-action',
	null
);
export const maidStorage = useLocalStorage<string>('maid', '');

export const sslhStorage = useLocalStorage<string>('sslh-invite', '');

export const isInAppBrowserStorage = useLocalStorage<boolean>(
	'in-app-browser',
	false
);

export const showAlerts = useLocalStorage<boolean>('show-alerts', false);

export const appLogStorage = useLocalStorage<boolean>('a-l', false);

export const appColorMode = useLocalStorage<string>(
	'app-color-mode',
	ColorModes.LIGHT
);

export const appKeyStorage = useLocalStorage<number>('app-key', 0);
