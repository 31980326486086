<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { getRootDomain } from '@/utils/helpers';
import DesktopNav from '@/components/navigation/DesktopNav.vue';
import { PrimeSidebar } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { isAuthorized } = storeToRefs(useAuthStore());
const { isLaptop, isTablet } = useScreenSize();

const homepageUrl = computed(() => {
	const rootDomain = getRootDomain();
	if (import.meta.env.MODE === 'development') {
		return `http://${rootDomain}:3001/`;
	} else {
		return `https://${rootDomain}`;
	}
});
</script>

<template>
	<PrimeSidebar v-if="!isTablet" :width="isLaptop ? '15rem' : '18.75rem'">
		<div class="app-logo">
			<component
				:is="isAuthorized ? 'router-link' : 'a'"
				:to="{ name: 'home' }"
				:href="homepageUrl"
			>
				<img src="../../assets/logo.svg" alt="Prime Opinion logo" />
			</component>
		</div>
		<div class="nav">
			<DesktopNav />
		</div>
	</PrimeSidebar>
</template>

<style scoped lang="scss">
.app-logo {
	display: flex;
	justify-content: center;

	img {
		width: 11.125rem;
		max-width: 100%;
		height: auto;
	}
}
.nav {
	margin-top: 2.75rem;
}
</style>
