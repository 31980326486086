import { watch } from 'vue';
import {
	CASHBACK_ANNOUNCEMENT_MODAL,
	CASHBACK_RECEIVED_NOTIFICATION_MODAL,
	LEADERBOARD_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels, LeaderboardTypes } from '@/enums';
import type { Notification } from '@/types';

export const useAppNotificationListener = (): void => {
	const { createNewModal } = useModalStorage();
	const messagesStore = useMessagesStore();
	const { notificationsList } = storeToRefs(messagesStore);
	const { userAuthToken } = storeToRefs(useAuthStore());

	const showLeaderboardModal = (options: Record<string, any>): void => {
		createNewModal(LEADERBOARD_MODAL, options);
	};

	const showNotificationsModal = ({ data, id }: Notification): void => {
		const requiredOptions = {
			id,
			channel: MessageChannels.NOTIFICATION,
			shared: true,
			onClose: () => messagesStore.readNotification(id),
		};
		switch (data.event_name) {
			case 'user-won-leaderboard-prize':
				showLeaderboardModal({
					type: LeaderboardTypes.LOCKED,
					rank: data.rank,
					coins: data.coins,
					...requiredOptions,
				});
				break;
			case 'ten-surveys-completed':
				showLeaderboardModal({
					type: LeaderboardTypes.UNLOCKED,
					...requiredOptions,
				});
				break;
			case 'offerwall_transaction_completed':
				createNewModal(OFFERWALLS_REWARD_NOTIFICATION_MODAL, {
					...requiredOptions,
					offer_name: data.offer_name,
					currency_earned: data.currency_earned,
				});

				break;

			case 'offerwall_transaction_delayed':
				createNewModal(OFFERWALLS_WELL_DONE_MODAL, {
					...requiredOptions,
					coin_value: data.coin_value,
					offer_name: data.offer_name,
					credit_delay: data.credit_delay,
				});

				break;
			case 'claim_cashback_received':
				createNewModal(CASHBACK_RECEIVED_NOTIFICATION_MODAL, {
					...requiredOptions,
					percentage: data.percentage,
				});
				break;
			case 'onetime-introduce-to-cashbacks':
				createNewModal(CASHBACK_ANNOUNCEMENT_MODAL, {
					...requiredOptions,
					expiry: data?.expiry,
					percentage: data?.percentage,
				});
				break;
			default:
				break;
		}
	};

	watch(
		[userAuthToken, notificationsList],
		async (newValue) => {
			const [token, notifications] = newValue;

			if (token && notifications?.length) {
				notifications.forEach((notification) => {
					showNotificationsModal(notification);
				});
			}
		},
		{ immediate: true }
	);
};
