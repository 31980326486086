<script setup lang="ts">
import { markRaw, ref } from 'vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import rocket from '@/assets/icons/rocket.svg';
import {
	PiHandCoinsDuotone,
	PiHcRocket,
	PiNavbar,
	PiPalette,
} from '@primeinsightsgroupllc/prime-icons';
import {
	ANNOUCEMENT_V2_HEAD,
	ANNOUCEMENT_V2_TITLE,
	ANNOUCEMENT_V2_SUBTITLE,
	ANNOUCEMENT_V2_BTN,
	ANNOUCEMENT_V2_LIST_1_TITLE,
	ANNOUCEMENT_V2_LIST_1_TEASER,
	ANNOUCEMENT_V2_LIST_2_TITLE,
	ANNOUCEMENT_V2_LIST_2_TEASER,
	ANNOUCEMENT_V2_LIST_3_TITLE,
	ANNOUCEMENT_V2_LIST_3_TEASER,
	ANNOUCEMENT_V2_LIST_4_TITLE,
	ANNOUCEMENT_V2_LIST_4_TEASER,
} from '@/locales/constants';
import { PrimeModalLayout } from '@primeinsightsgroupllc-modals/prime-modals';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const isDisable = ref(false);
const items = markRaw([
	{
		id: 1,
		icon: PiPalette,
		title: ANNOUCEMENT_V2_LIST_1_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_1_TEASER,
	},
	{
		id: 2,
		icon: PiHandCoinsDuotone,
		title: ANNOUCEMENT_V2_LIST_2_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_2_TEASER,
	},
	{
		id: 3,
		icon: PiNavbar,
		title: ANNOUCEMENT_V2_LIST_3_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_3_TEASER,
	},
	{
		id: 4,
		icon: PiHcRocket,
		title: ANNOUCEMENT_V2_LIST_4_TITLE,
		subtitle: ANNOUCEMENT_V2_LIST_4_TEASER,
	},
]);

const handleSubmit = () => {
	isDisable.value = true;
	emit('close-modal');
};
</script>
<template>
	<PrimeModalLayout>
		<template #title>
			<PrimeText weight="600" align="center">
				{{ $t(ANNOUCEMENT_V2_HEAD, { version: '2.0' }) }}
			</PrimeText>
		</template>
		<template #content>
			<div class="icon scale-in">
				<img :src="rocket" alt="rocket" />
			</div>

			<PrimeText
				weight="600"
				align="center"
				size="lg"
				class="annoucement-title"
			>
				{{ $t(ANNOUCEMENT_V2_TITLE, { version: '2.0' }) }} 🚀
			</PrimeText>

			<PrimeText
				weight="500"
				align="center"
				size="sm"
				color="grey-700"
				class="annoucement-subtitle"
			>
				{{ $t(ANNOUCEMENT_V2_SUBTITLE) }}
			</PrimeText>

			<div class="annoucement-info">
				<div v-for="item in items" :key="item.id" class="annoucement-item">
					<component :is="item.icon" color="inherit" class="annoucement-icon" />
					<div>
						<PrimeText size="xs" weight="700"> {{ $t(item.title) }} </PrimeText
						>:
						<PrimeText size="xs"> {{ $t(item.subtitle) }}</PrimeText>
					</div>
				</div>
			</div>
		</template>
		<template #bottom>
			<PrimeButton
				type="fill"
				full-width
				:label="$t(ANNOUCEMENT_V2_BTN, { version: '2.0' })"
				:disabled="isDisable"
				rounded="rounded"
				@click="handleSubmit"
			/>
		</template>
	</PrimeModalLayout>
</template>
<style scoped lang="scss">
@import '@/styles/mixins.scss';

.icon {
	display: flex;
	justify-content: center;
	margin: 0 0 1.375rem 0;
}

.annoucement-subtitle {
	display: block;
	width: 100%;
	margin-top: 0.75rem;
}

.annoucement-info {
	width: 100%;
	padding: 1rem 0.875rem;
	border-radius: 0.75rem;
	background: var(--base-primary-50);
	margin-top: 1.375rem;
	color: var(--base-primary);
}

.annoucement-item {
	display: flex;
	align-items: flex-start;
	gap: 0.75rem;
	margin-bottom: 0.875rem;
}

.annoucement-item:last-child {
	margin-bottom: 0;
}

.annoucement-icon {
	flex: none;
}
</style>
