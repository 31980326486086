<script setup lang="ts">
import RewardItem from '@/views/surveys/components/user-dashboard/rewards/RewardItem.vue';
import type { RewardsItem } from '@/types';
import { computed } from 'vue';
import {
	CLICK_TO_SIGNUP,
	REVOLUT_REWARD_SIGNUP_DESCRIPTION,
} from '@/locales/constants';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';
import { useUserStore } from '@/stores/user';

const { reward } = defineProps<{
	reward: RewardsItem;
	selectedOptionId: number;
}>();

defineEmits<{
	'select-option': [number];
}>();

const { rewardsMeta } = storeToRefs(useRewardsStore());
const { userId } = storeToRefs(useUserStore());

const revolutReward = computed(() => ({
	...reward,
	image_url: `${import.meta.env.VITE_CDN_DOMAIN}/images/revolut_secondary.svg`,
}));

const revolutUrl = computed(
	() => `${import.meta.env.VITE_REVOLUT_URL}${userId.value}`
);

const revolutPixelUrl = computed(
	() => `${import.meta.env.VITE_REVOLUT_PIXEL_URL}${userId.value}`
);
</script>

<template>
	<div class="revolut-reward">
		<RewardItem
			:key="revolutReward.reward_external_id"
			:item="revolutReward"
			:selected-option-id="selectedOptionId"
			:item-name="false"
			:item-discount="
				rewardsMeta?.revolut_has_claim && revolutReward.has_discounted_options
			"
			accent-color="text-default"
			@select-option="$emit('select-option', $event)"
		>
			<template v-if="!rewardsMeta?.revolut_has_claim" #reward-description>
				<div class="revolut-signup__container">
					<PrimeText size="xs" weight="500" tag="p">
						{{ $t(REVOLUT_REWARD_SIGNUP_DESCRIPTION) }} 🎉
					</PrimeText>
					<a :href="revolutUrl" target="_blank">
						<PrimeText color="primary" size="xs" weight="600">
							{{ $t(CLICK_TO_SIGNUP) }}
						</PrimeText>
					</a>
					<img border="0" width="1" height="1" :src="revolutPixelUrl" />
				</div>
			</template>
		</RewardItem>
	</div>
</template>

<style lang="scss" scoped>
.revolut-signup__container {
	padding: 0.5rem;
	background-color: var(--grey-400);
	border-radius: 0.5rem;
	margin-bottom: 1.125rem;

	& p {
		margin-bottom: 0.375rem;
	}

	& a:hover span {
		text-decoration: underline;
	}
}
</style>
