<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import OverlayPanel from 'primevue/overlaypanel';
import {
	PrimeButton,
	PrimeDivider,
	PrimeText,
	useRippleHoverEffect,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	CURRENT_STREAK,
	STREAK_RULES,
	STREAK_WEEKLY_REWARD,
	PRIZE_DRAW,
	DRAW_PRIZE,
	UP_TO_COINS,
	PRIZE_CLAIMED,
	CHECK_TOMORROW,
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
} from '@/locales/constants';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { PRIZE_DRAW_MODAL } from '@/constants/modals';
import { localizePoints } from '@/utils/helpers';
import {
	PiCheck,
	PiFireDuotone,
	PiGiftDuotone,
	PiQuestionCircle,
	PiTrophyDuotone,
} from '@primeinsightsgroupllc/prime-icons';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const {
	maxPrize,
	streakDayStart,
	weekLenght,
	prizes,
	streaks,
	completedDays,
	isTodayGoalCompleted,
} = storeToRefs(useUserStore());
const { createNewModal } = useModalStorage();
const { createRipple } = useRippleHoverEffect();

const buttonRef = ref<HTMLElement | null>(null);
const reorderedDays = computed(() => {
	const weekDays = [
		{ id: 0, name: t(SUNDAY) },
		{ id: 1, name: t(MONDAY) },
		{ id: 2, name: t(TUESDAY) },
		{ id: 3, name: t(WEDNESDAY) },
		{ id: 4, name: t(THURSDAY) },
		{ id: 5, name: t(FRIDAY) },
		{ id: 6, name: t(SATURDAY) },
	];
	const actualWeekDaysStart = weekDays.slice(streakDayStart.value);
	const actualWeekDaysEnd = weekDays.slice(0, streakDayStart.value);

	return [...actualWeekDaysStart, ...actualWeekDaysEnd];
});

const userStreakInfo = ref<InstanceType<typeof OverlayPanel> | null>(null);

const toggleUserStreakInfo = (event: MouseEvent, useRipple = true) => {
	if (useRipple) createRipple(buttonRef.value, event);
	userStreakInfo.value?.toggle(event);
};

//TODO replace type any
const onPrizeDraw = (event: any) => {
	toggleUserStreakInfo(event);
	router.push({ name: 'prize-draw' });
};

const openPrizeDrawModal = (event: any) => {
	toggleUserStreakInfo(event, false);

	createNewModal(PRIZE_DRAW_MODAL);
};

watch(route, async () => {
	if (userStreakInfo.value) {
		userStreakInfo.value?.hide();
	}
});
</script>

<template>
	<div class="user-streak">
		<!-- Icon with user streak -->
		<button
			ref="buttonRef"
			class="user-streak--button"
			@click="toggleUserStreakInfo"
		>
			<PrimeText
				class="notranslate"
				tag="span"
				size="sm"
				weight="600"
				:color="isTodayGoalCompleted ? 'secondary' : 'default'"
			>
				{{ streaks }}
			</PrimeText>
			<PiFireDuotone
				:color="isTodayGoalCompleted ? 'base-secondary' : 'text-default'"
			/>
		</button>

		<!-- OverlayPanel with user streak info -->
		<OverlayPanel
			id="user-streak--info"
			ref="userStreakInfo"
			append-to="body"
			style="width: 16.25rem"
		>
			<div class="streak-info--rules">
				<PiFireDuotone
					:color="isTodayGoalCompleted ? 'base-secondary' : 'text-default'"
				/>
				<div>
					<PrimeText tag="p" size="sm" weight="600">
						{{ $t(CURRENT_STREAK) }}: {{ streaks }}
					</PrimeText>
					<PrimeText tag="p" size="xs" color="grey-600">
						{{ $t(STREAK_RULES) }}
					</PrimeText>
				</div>
			</div>
			<div v-if="weekLenght" class="streak-info--progress">
				<div
					v-for="(day, index) in reorderedDays"
					:key="day.id"
					class="streak-info--progress-day"
				>
					<PrimeText class="streak-info--progress-day-name" weight="500">
						{{ day.name }}
					</PrimeText>
					<div
						class="streak-info--progress-day-icon"
						:class="{
							set: index <= completedDays - 1,
							completed:
								day.id === reorderedDays[weekLenght - 1].id &&
								completedDays === weekLenght,
						}"
					>
						<PiCheck v-if="index !== weekLenght - 1" size="14" color="white" />
						<PiTrophyDuotone v-else size="20" color="white" />
					</div>
				</div>
			</div>
			<div class="streak-info--reward">
				<PrimeButton
					v-if="prizes > 0"
					:label="$t(DRAW_PRIZE)"
					variant="secondary"
					full-width
					@click="onPrizeDraw"
				>
					<template #icon>
						<PiGiftDuotone color="white" />
					</template>
				</PrimeButton>
				<template v-else-if="prizes === 0 && completedDays === weekLenght">
					<PrimeText class="streak-info--claimed-title" weight="600" tag="p">
						{{ $t(PRIZE_CLAIMED) }}
					</PrimeText>
					<PrimeText size="sm" tag="p" color="grey-700">
						{{ $t(CHECK_TOMORROW) }}
					</PrimeText>
				</template>
				<template v-else>
					<PrimeDivider color="grey-100" bottom-gap="0.625rem" />
					<PrimeText
						class="streak-info--reward-title"
						tag="p"
						weight="500"
						size="sm"
					>
						{{ $t(STREAK_WEEKLY_REWARD) }}
					</PrimeText>
					<div class="streak-info--reward-row">
						<div class="streak-info--reward-content">
							<PiGiftDuotone size="22" color="base-secondary" />
							<div class="streak-info--reward-draws">
								<PrimeText tag="p" weight="600" size="sm" color="secondary">
									1 {{ $t(PRIZE_DRAW) }}
								</PrimeText>
								<PrimeText size="xs" color="grey-600" weight="500">
									<span
										v-html="
											`${$t(UP_TO_COINS, { amount: localizePoints(maxPrize) })}`
										"
									/>
								</PrimeText>
							</div>
						</div>
						<PrimeButton
							type="outline"
							variant="grey"
							rounded="rounded"
							class="streak-info--reward-modal-trigger"
							@click="openPrizeDrawModal"
						>
							<template #icon>
								<PiQuestionCircle />
							</template>
						</PrimeButton>
					</div>
				</template>
			</div>
		</OverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.user-streak {
	flex: none;
	z-index: 1;

	&--button {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 2.125rem;
		height: 2rem;
		border: 0;
		border-radius: 3.125rem;
		color: var(--grey-default);
		background: var(--grey-50);
		font-weight: 600;
		cursor: pointer;
		padding: 0 0.25rem;

		svg {
			flex: none;
			width: 1.25rem;
			height: 1.25rem;
			margin-left: 0.188rem;
		}

		@include breakpoint(mobile) {
			svg {
				height: 1.5rem;
				width: 1.5rem;
			}

			& {
				min-width: 4.75rem;
				height: 2.5rem;
				padding: 0 0.375rem;
			}
		}

		&:hover {
			background-color: var(--grey-100);
		}
	}
}

#user-streak--info .p-overlaypanel-content {
	padding: 0.75rem;
	.streak-info {
		&--rules {
			display: flex;
			gap: 0.625rem;
			& > div {
				display: flex;
				flex-direction: column;
				gap: 0.125rem;
			}
			& > svg {
				flex-shrink: 0;
			}
		}
		&--progress {
			display: flex;
			justify-content: space-between;
			margin-top: 0.375rem;
			margin-bottom: 0.625rem;

			&-day {
				&-name {
					font-size: 0.625rem;
				}
				&-icon {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: var(--grey-400);
					color: white;
					width: 1.5rem;
					height: 1.5rem;
					border-radius: 50%;
					&.set {
						background-color: var(--base-primary);
					}
					&.completed {
						border-color: var(--base-secondary) !important;
						background-color: var(--base-secondary);
					}
				}
				&:nth-child(7) .streak-info--progress-day-icon {
					border: 1px solid var(--grey-500);
				}
			}
		}
		&--reward {
			&-title {
				padding-bottom: 0.3125rem;
			}
			&-content {
				display: flex;
				align-items: center;

				& > span {
					margin-inline-end: 0.375rem;
					align-self: center;
					display: flex;
				}
			}
			&-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.625rem;
			}
			&-draws {
				margin-left: 0.5rem;
				display: flex;
				flex-direction: column;
				gap: 0.125rem;
			}
			&-modal-trigger {
				border: 0;
				padding: 0;

				& .p-btn-icon {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}
		&--claimed-title {
			text-align: center;
			margin-bottom: 0.125rem;
			& + p {
				text-align: center;
			}
		}
	}
}
</style>
