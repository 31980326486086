<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { LevelTier } from '@/types';
import { LEVEL, UPCOMING_BENEFITS } from '@/locales/constants';
import { tiersBenefits } from '@/constants/tiers-benefits';
import TierCircle from '@/components/tiers/TierCircle.vue';
import PrimeTag from '@/components/common/PrimeTag.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { t } = useI18n();

const props = defineProps<{
	tier: LevelTier;
}>();

const levelsString = computed(() => {
	if (props.tier.end_level) {
		return `${props.tier.start_level}-${props.tier.end_level} ${t(
			LEVEL
		).toLowerCase()}`;
	} else {
		return `${props.tier.start_level}+ ${t(LEVEL).toLowerCase()}`;
	}
});
</script>

<template>
	<div class="tier-item__wrapper">
		<TierCircle
			class="tier-item__tier-badge"
			size="1.5rem"
			:tier-key="tier.key"
		/>
		<div class="tier-item__levels-details">
			<PrimeText size="xs" color="grey-600" weight="600">
				{{ levelsString }}
			</PrimeText>
			<PrimeText weight="600">{{ $t(tier.key) }}</PrimeText>
		</div>
		<div v-if="tier.is_available" class="tier-item__benefits">
			<PrimeText v-if="tier.benefit" size="xs" weight="500">
				{{ $t(tiersBenefits[tier.benefit]) }}
			</PrimeText>
		</div>
		<PrimeTag v-else color="base-primary" :text="$t(UPCOMING_BENEFITS)" />
	</div>
</template>

<style lang="scss" scoped>
.tier-item__wrapper {
	padding: 0.5rem;
	border: 1px solid var(--grey-300);
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
}

.tier-item__levels-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 auto;

	& .p-text {
		line-height: 1;
	}
}

.tier-item__benefits {
	flex: 0 1 35%;
	text-align: end;
}
</style>
