<script setup lang="ts">
import type { Component } from 'vue';
import type { RewardsItem } from '@/types';
import RevolutReward from '@/views/surveys/components/user-dashboard/rewards/promoted/RevolutReward.vue';
import RewardItem from '@/views/surveys/components/user-dashboard/rewards/RewardItem.vue';
import { PromotedRewards } from '@/enums';

const components: Record<PromotedRewards, Component> = {
	[PromotedRewards.REVOLUT]: RevolutReward,
};

const { items } = defineProps<{
	items: RewardsItem[];
	selectedOption: number;
}>();

defineEmits<{
	'select-option': [number];
}>();

const getRewardComponents = (id: PromotedRewards): Component => {
	return components[id] || RewardItem;
};
</script>

<template>
	<div class="promoted-rewards">
		<component
			:is="getRewardComponents(reward.reward_external_id as PromotedRewards)"
			v-for="reward in items"
			:key="reward.reward_external_id"
			:reward="reward"
			:selected-option-id="selectedOption"
			@select-option="$emit('select-option', $event)"
		/>
	</div>
</template>

<style lang="scss" scoped>
.promoted-rewards {
	padding: 0.75rem 0;
	background: var(--grey-100);
	border-radius: 1rem;
}
</style>
