<script lang="ts" setup>
import type { IconColors } from '@/types';
import { hexToRgba } from '@/utils/helpers';
import { computed } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = withDefaults(
	defineProps<{
		text: string;
		color: IconColors;
	}>(),
	{
		color: 'base-primary',
	}
);

const colorValue = computed(() => {
	const color = getComputedStyle(document.documentElement).getPropertyValue(
		`--${props.color}`
	);

	return color ? color : '#164ed0';
});

const backgroundColor = computed(
	() => `rgba(${hexToRgba(colorValue.value, 0.05)})`
);
</script>

<template>
	<div class="prime-tag__wrapper">
		<PrimeText weight="600" size="xs" class="prime-tag__text">
			{{ text }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
.prime-tag__wrapper {
	background-color: v-bind(backgroundColor);
	padding: 0.25rem 0.375rem;
	border-radius: 3.125rem;
}

.prime-tag__text {
	color: v-bind(colorValue) !important;
}
</style>
