<script setup lang="ts">
import {
	BONUS_DAY,
	BONUS_DAY_TARGET,
	BONUS_DAY_UNLOCK_DESCRIPTION,
	TAKE_SURVEYS,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import TierCircle from '@/components/tiers/TierCircle.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiLockSimpleDuotone } from '@primeinsightsgroupllc/prime-icons';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';

const { bonusDayBonus } = storeToRefs(useUserStore());

const emits = defineEmits<{
	'close-modal': [];
}>();

const router = useRouter();

const takeSurveys = () => {
	emits('close-modal');
	router.push({ name: 'surveys' });
};
</script>

<template>
	<div class="bonus-day-modal">
		<ModalCommonContent>
			<template #header>{{ $t(BONUS_DAY, { value: bonusDayBonus }) }}</template>
			<template #default>
				<TierCircle class="tier-circle" size="3.75rem" :tier-key="'silver'">
					<PiLockSimpleDuotone size="1.75rem" color="white" />
				</TierCircle>
				<PrimeText weight="700">
					{{ $t(BONUS_DAY, { value: bonusDayBonus }) }}
				</PrimeText>

				<PrimeText weight="600" class="bonus-day__target">
					{{ $t(BONUS_DAY_TARGET) }}
				</PrimeText>

				<PrimeText
					weight="500"
					size="sm"
					color="grey-700"
					class="bonus-day__description"
				>
					{{ $t(BONUS_DAY_UNLOCK_DESCRIPTION, { value: bonusDayBonus }) }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(TAKE_SURVEYS)"
					@click="takeSurveys"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss">
.bonus-day-modal {
	& .tier-circle {
		margin: 0.3125rem 0.3125rem 0.75rem;
	}

	& .bonus-day__target {
		margin-top: 0.25rem;
	}

	& .bonus-day__description {
		margin-top: 1rem;
	}
}
</style>
