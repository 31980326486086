import API from '@/api';
import { getPaginatedData } from '@/api';
import type { LeaderboardMeta } from '@/types';

export const getLeaderboardMeta = async (): Promise<LeaderboardMeta> => {
	const { data } = await API.get<LeaderboardMeta>('/api/v3/leaderboard/meta');

	return data;
};

export const getLeaderboardUsers = async (offset: number, page: number) => {
	const data = await getPaginatedData(
		'/api/v2/leaderboard/users',
		offset,
		page
	);
	return data;
};
