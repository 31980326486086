<script setup lang="ts">
import { computed } from 'vue';
import { getRootDomain } from '@/utils/helpers';
import LanguageSwitcher from '@/views/app-login/components/LanguageSwitcher.vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import logo from '@/assets/logo-white.svg';

const { isMobileApp } = useMobileApp();

const homepageUrl = computed(() => {
	const rootDomain = getRootDomain();
	if (import.meta.env.MODE === 'development') {
		return `http://${rootDomain}:3001/`;
	} else {
		return `https://${rootDomain}`;
	}
});
</script>

<template>
	<header class="header">
		<component
			:is="!isMobileApp ? 'a' : 'div'"
			:href="!isMobileApp ? homepageUrl : ''"
			class="logo"
		>
			<img :src="logo" alt="Prime Opinion logo" />
		</component>
		<LanguageSwitcher />
	</header>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: calc(1.875rem + env(safe-area-inset-top)) 1.5rem 1.875rem;

	@include breakpoint(mobile) {
		padding: calc(1.875rem + env(safe-area-inset-top)) 2.75rem 1.875rem;
	}
}
.logo img {
	display: block;
	width: 9.375rem;
	height: auto;

	@include breakpoint(mobile) {
		width: 14.188rem;
	}
}
</style>
